import isNull from 'lodash/isNull'

import Product from '@/database/models/Product'
import AutoCodableN from '~/database/models/AutoCodableN'

class WantlistItem extends AutoCodableN {
  static entity = 'wantlistitem'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      created: this.attr(null),
      product: this.attr(null),
      foil: this.attr(null),
      count: this.attr(null),
      min_condition: this.attr(null),
      english_only: this.attr(null),
      customer_status: this.attr(null),
      notify_on_any_stockup: this.attr(null),
      cheapest_price_available_czk: this.attr(null),
      cheapest_price_available_eur: this.attr(null),
      num_currently_in_stock: this.attr(null)
    }
  }

  static CustomerStatus = {
    active: 'ACTIVE',
    deactivated: 'DEACTIVATED',
    deleted: 'DELETED'
  }

  static propertyMap = new Map([
    ['minCondition', 'min_condition'],
    ['englishOnly', 'english_only'],
    ['customerStatus', 'customer_status'],
    ['notifyOnAnyStockup', 'notify_on_any_stockup'],
    ['cheapestPriceAvailableCzk', 'cheapest_price_available_czk'],
    ['cheapestPriceAvailableEur', 'cheapest_price_available_eur'],
    ['numCurrentlyInStock', 'num_currently_in_stock']
  ])

  get minConditionFormatted() {
    const condition = this.minCondition

    return `${condition}${condition === 'NM' ? '' : '+'}`
  }

  get isActive () {
    return this.customerStatus === WantlistItem.CustomerStatus.active
  }

  get hasItemsOnStock () {
    return this.numCurrentlyInStock > 0
  }

  get hasProduct () {
    return !isNull(this.product)
  }

  get productObject () {
    if (this.hasProduct) return new Product(this.product)
    return {}
  }

  get isNonSingle () {
    return this.productObject.isNonSingle
  }

  get articles () {
    if (this.hasProduct) {
      return this.productObject.articleObjects
    }

    return []
  }

  get hasArticles () {
    return this.articles.length > 0
  }

  get hasMultipleArticles () {
    return this.articles.length > 1
  }

  get title () {
    return this.productObject.title
  }

  get titleLoc () {
    return this.productObject.titleLoc
  }

  get expansionTitleLoc () {
    return this.productObject.expansionTitleLoc
  }

  get expansionShortCode () {
    return this.productObject.expansionShortCode
  }

  get expansionIcon () {
    return this.productObject.expansionIcon
  }
}

export default WantlistItem
