import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'


import ShippingAccessPoint from '@/structures/ShippingAccessPoint'
import Codable from '@/database/models/Codable'
import ShippingAddress from '@/database/models/ShippingAddress'
import BillingAddress from '@/database/models/BillingAddress'
import PaymentMethod from '@/structures/PaymentMethod'
import { dayMonth, subDays } from '@/services/DateService'
import Payment from '@/structures/Payment'
import { findObject } from '@/services/Helpers'
import { getLoc } from '~/structures/Localizable'
import OrderItem from '~/structures/OrderItem'
import ShippingMethod from '~/structures/ShippingMethod'
import Voucher from '~/structures/Voucher'

class Order extends Codable {
  static entity = 'orders'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      created: this.attr(null),
      date_shipped: this.attr(null),
      date_cancelled: this.attr(null),
      payment_status: this.attr('UNPAID'),
      customer_status: this.attr(null),
      note_to_customer: this.attr(null),
      note_from_customer: this.attr(null),
      display_price: this.attr(null),
      amount_to_pay: this.attr(null),
      currency: this.attr('CZK'),
      email: this.attr(null),
      username: this.attr(null),
      shipping_method: this.attr('CZ'),
      payment_method: this.attr(''),
      shipping_price: this.attr(''),
      payment_method_price: this.attr(''),
      shipping_address: this.attr(''),
      billing_address: this.attr(''),
      anonymous_access_key: this.string(''),
      has_lottery_available: this.attr(false),
      tracking_code: this.attr(null),
      items: this.attr([]),
      payments: this.attr([]),
      has_presale_items: this.attr(false),
      max_presale_release_date: this.attr(null),
      loyalty_discount_total: this.attr(0),

      shipping_access_point: this.attr(null),
      shipping_access_point_info: this.attr(null),

      applied_vouchers: this.attr([]),

      potential_next_loyalty_level: this.attr({}),
      has_rating: this.attr(false),
    }
  }

  static propertyMap = new Map([
    ['shippingAddress', 'shipping_address'],
    ['shippingMethod', 'shipping_method'],
    ['billingAddress', 'billing_address'],
    ['paymentMethod', 'payment_method'],
    ['noteFromCustomer', 'note_from_customer'],
    ['noteToCustomer', 'note_to_customer'],
    ['agreeToMarketingEmails', 'agree_to_marketing_emails'],
    ['agreeToTermsAndConditions', 'agree_to_terms_and_conditions'],
    ['useCreditForPayment', 'use_credit_for_payment'],
    ['registerUserAccount', 'register_user_account'],
    ['newUserAccountPassword', 'new_user_account_password'],
    ['shippingAccessPoint', 'shipping_access_point'],
    ['hasLotteryAvailable', 'has_lottery_available'],
    ['trackingCode', 'tracking_code'],

    // Buylist Order
    ['billingFirstName', 'billing_first_name'],
    ['billingLastName', 'billing_last_name'],
    ['billingStreet', 'billing_street'],
    ['billingStreet2', 'billing_address_2'],
    ['billingZip', 'billing_zip'],
    ['billingCity', 'billing_city'],
    ['billingCountry', 'billing_country'],
    ['billingCompany', 'billing_company'],
    ['shippingFirstName', 'shipping_first_name'],
    ['shippingLastName', 'shipping_last_name'],
    ['shippingStreet', 'shipping_street'],
    ['shippingStreet2', 'shipping_address_2'],
    ['shippingZip', 'shipping_zip'],
    ['shippingCity', 'shipping_city'],
    ['shippingCountry', 'shipping_country'],
    ['shippingCompany', 'shipping_company'],
    ['agreeToMarketingEmails', 'agree_to_marketing_emails'],
    ['agreeToTermsAndCondition', 'agree_to_terms_and_conditions'],
    ['paymentType', 'payment_type'],
    ['deliveryMethod', 'delivery_method'],
    ['customerNotifySettings', 'customer_notify_settings'],
    ['noteFromCustomer', 'note_from_customer'],
    ['paypalEmail', 'paypal_email'],
    ['bankAccountNumber', 'bank_account_number'],
    ['registerUserAccount', 'register_user_account'],
    ['newUserAccountPassword', 'new_user_account_password']
  ])

  static paymentType = {
    CASH_ON_DELIVERY: 'CASH_ON_DELIVERY',
    CASH_IN_STORE: 'CASH_IN_STORE',
    CARD_ONLINE: 'CARD_ONLINE',
    TRANSFER: 'TRANSFER',
    CREDIT: 'CREDIT',
    PAYPAL: 'PAYPAL'
  }

  static paymentStatusType = {
    PAID: 'PAID',
    UNPAID: 'UNPAID',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
    CASH_ON_DELIVERY: 'CASH_ON_DELIVERY'
  }

  static customerStatusType = {
    new: 'NEW',
    presale: 'PRESALE',
    orderFromSupplier: 'ORDER_FROM_SUPPLIER',
    warehouse: 'WAREHOUSE',
    shop: 'SHOP',
    moveToShop: 'MOVE_TO_SHOP',
    readyForPickup: 'READY_FOR_PICKUP',
    readyForShipping: 'READY_FOR_SHIPPING',
    shipped: 'SHIPPED',
    merged: 'MERGED',
    expired: 'EXPIRED',
    cancelled: 'CANCELLED',
    delivered: 'DELIVERED',
    undelivered: 'UNDELIVERED',
    returned: 'RETURNED'
  }

  static creditPaymentType = new PaymentMethod({
    id: 'Credits',
    type: 'credits',
    icon: 'credit',
    localized_name: 'payment-method_credit',
    name: 'payment-method_credit',
    name_cz: 'payment-method_credit',
    name_de: 'payment-method_credit'
  })

  static voucherPaymentType = new PaymentMethod({
    id: 'Vouchers',
    type: 'vouchers',
    icon: 'voucher',
    localized_name: 'payment-method_voucher',
    name: 'payment-method_voucher',
    name_cz: 'payment-method_voucher',
    name_de: 'payment-method_voucher'
  })

  get isInStorePickup() {
    return this.shipping_method?.type === 'IN_STORE_PICKUP'
  }

  get hasRating() {
    return this.has_rating
  }

  get currencyLower() {
    return this.currency.toLowerCase()
  }

  get potentialNextLoyaltyLevel () {
    return this.potential_next_loyalty_level || {}
  }

  get trackingCode () {
    return this.tracking_code
  }

  get hasTrackingCode () {
    return !isNull(this.trackingCode) && !isEmpty(this.trackingCode)
  }

  get trackingLinkLoc() {
    const trackingCode = this.trackingCode
    const shippingMethod = this.shippingMethod
    const value = getLoc(null, null, null)

    for (const languageKey in value) {
      let link = null

      if (shippingMethod && shippingMethod.type === ShippingMethod.Type.UPS) {
        link = `https://www.ups.com/WebTracking/processInputRequest?tracknum=${trackingCode}`
      } else if (shippingMethod && shippingMethod.type === ShippingMethod.Type.ZASILKOVNA) {
        link = `https://tracking.packeta.com/${languageKey}/?id=${trackingCode}`
      } else if (shippingMethod && shippingMethod.type === ShippingMethod.Type.CZECH_POST) {
        link = `https://www.postaonline.cz/${languageKey}/trackandtrace/-/zasilka/cislo?parcelNumbers=${trackingCode}`
      } else if(shippingMethod && shippingMethod.type === ShippingMethod.Type.GLS) {
        link = `http://online.gls-czech.com/index.php?page=tt_page.php?tt_value=${trackingCode}`
      }

      value[languageKey] = link
    }

    return value
  }

  get orderItems () {
    return map(this.items, item => new OrderItem(item))
  }

  get overviewItems () {
    let items = [...this.items]
    items = orderBy(items, /** @type {OrderItem} */ orderItem => orderItem.isCheckoutReward)

    if (this.paymentMethod[`price_${this.currencyLower}`] > 0) {
      items = [...items, this.paymentMethod]
    }
    if (this.shippingMethod[`price_${this.currencyLower}`] > 0) {
      items = [...items, this.shippingMethod]
    }

    // If credit is only payment
    if(this.isFullCreditPayment || this.isPaymentPartialCredit) {
      /** @type {Payment} */
      const payment = findObject(this.orderPayments, payment => payment.type === Order.paymentType.CREDIT)

      if (payment) {
        const paymentMethod = new PaymentMethod({ ...payment.paymentMethod, price_czk: -payment.amount, price_eur: -payment.amount })
        paymentMethod.totalPrice = -payment.amount
        delete paymentMethod.price
        items = [...items, paymentMethod]
      }
    }

    if (this.isPaymentVoucher) {
      const voucher = this.appliedVouchers[0]
      voucher.localized_name = 'discount_code'
      voucher.id = 'discount'

      items = [...items, voucher]
    }

    return items
  }

  get displayDate() {
    let date = null

    if(this.isCancelled) {
      date = this.dateCancelled
    } else if(this.hasShipped) {
      date = this.dateShipped
    }

    return date
  }

  get dateShipped () {
    return this.date_shipped
  }

  get hasShipped () {
    return !isNull(this.dateShipped)
  }

  get dateCancelled () {
    return this.date_cancelled
  }

  get isEligibleForRating() {
    return !this.hasRating && this.hasShipped && this.dateShipped > subDays(new Date(), 14).toISOString()
  }

  get hasShippingAccessPointInfo () {
    return this.shipping_access_point_info !== null
  }

  get shippingAccessPointInfo () {
    return new ShippingAccessPoint(this.shipping_access_point_info)
  }

  get isCompleted () {
    if (this.isCancelled || this.isDelivered || this.isUndelivered || this.isReturned) return true
    if (this.isReadyForPickup) return false
    return this.hasShipped
  }

  get isCancelled () {
    return this.customerStatus === Order.customerStatusType.cancelled
  }

  get isDelivered () {
    return this.customerStatus === Order.customerStatusType.delivered
  }

  get isUndelivered () {
    return this.customerStatus === Order.customerStatusType.undelivered
  }

  get isReturned () {
    return this.customerStatus === Order.customerStatusType.returned
  }

  get isReadyForPickup () {
    return this.customerStatus === Order.customerStatusType.readyForPickup
  }

  get isUnpaid () {
    const unpaidStates = [Order.paymentStatusType.UNPAID, Order.paymentStatusType.PAYMENT_PENDING, Order.paymentStatusType.CASH_ON_DELIVERY]
    return unpaidStates.includes(this.paymentStatus)
  }

  get isPresale () {
    return this.has_presale_items
  }

  get orderStatusIcon () {
    if (this.isCancelled) return 'close-rounded-red'
    else if (this.isUndelivered) return 'check-rounded-red'
    else if (this.isReturned) return 'check-rounded-red'
    else if (this.isDelivered) return 'check-rounded'
    else if (this.isReadyForPickup) return 'check-rounded'
    else if (this.hasShipped) return 'truck'
    else return 'box-open'
  }

  get orderStatusLocalized () {
    if (this.isCancelled) return 'order_state_cancelled'
    else if (this.isUndelivered) return 'order_state_undelivered'
    else if (this.isReturned) return 'order_state_returned'
    else if (this.isDelivered) return 'order_state_delivered'
    else if (this.isReadyForPickup) return 'order_state_ready_for_pickup'
    else if (this.hasShipped) return 'order_state_shipped_to_customer'
    else if (this.isUnpaid && (this.isPaymentCashInStore || this.isPaymentCashOnDelivery) && !this.isPresale) return 'order_state_preparing' // Dobirka + hotovost
    else if (this.isPresale) return 'order_state_presale'
    else if (this.isUnpaid) return 'order_state_awaiting_payment'
    else return 'order_state_preparing'
  }

  get maxPresaleReleaseDate () {
    return this.max_presale_release_date
  }

  get presaleReleaseDate () {
    return dayMonth(this.maxPresaleReleaseDate)
  }

  get presaleReleaseDateShort () {
    return dayMonth(this.maxPresaleReleaseDate)
  }

  /** PAYMENT */

  get orderPayments () {
    return map(this.payments, payment => {
      return new Payment(payment)
    })
  }

  get paymentStatus () {
    return this.payment_status
  }

  get customerStatus () {
    return this.customer_status
  }

  get noteToCustomer () {
    return this.note_to_customer
  }

  get noteFromCustomer () {
    return this.note_from_customer
  }

  get amountToPay () {
    return this.amount_to_pay
  }

  get displayPrice () {
    return this.display_price
  }

  get price () {
    let price = this.displayPrice
    const creditPayment = findObject(this.orderPayments, payment => payment.type === Order.paymentType.CREDIT)

    if (creditPayment && price > 0) {
      const paymentMethod = { ...creditPayment.paymentMethod }
      price = price - paymentMethod.price
    }

    return price
  }

  get shippingMethod () {
    const localizedName = this.shipping_method?.localized_name
    return new ShippingMethod({
      name: localizedName,
      name_cz: localizedName,
      name_de: localizedName,
      ...this.shipping_method,
      price_czk: this.shippingMethodPrice,
      price_eur: this.shippingMethodPrice
    })
  }

  get paymentMethod () {
    if (this.isPaymentVoucher) {
      return new PaymentMethod({
        price_czk: 0,
        price_eur: 0,
        icon: 'voucher',
        localized_name: 'discount_code',
        name: 'discount_code',
        name_cz: 'discount_code',
        name_de: 'discount_code',
      })
    }

    return new PaymentMethod({
      ...this.payment_method,
      price_czk: this.paymentMethodPrice,
      price_eur: this.paymentMethodPrice
    })
  }

  get appliedVouchers () {
    return map(this.applied_vouchers, voucher => {
      return new Voucher(voucher)
    })
  }

  get hasAppliedVoucher () {
    return !isEmpty(this.appliedVouchers)
  }

  get shippingMethodPrice () {
    return this.shipping_price
  }

  get paymentMethodPrice () {
    return this.payment_method_price
  }

  get isPaymentCashInStore() {
    return this.paymentMethod.type === Order.paymentType.CASH_IN_STORE
  }

  get isPaymentCashOnDelivery() {
    return this.paymentMethod.type === Order.paymentType.CASH_ON_DELIVERY
  }

  get isPaymentTransfer () {
    return this.paymentMethod.type === Order.paymentType.TRANSFER
  }

  get isPaymentVoucher () {
    return this.hasAppliedVoucher && isEmpty(this.orderPayments) && this.paymentStatus === Order.paymentStatusType.PAID
  }

  get isPaymentCard () {
    return this.paymentMethod.type === Order.paymentType.CARD_ONLINE
  }

  get isPaymentPaypal () {
    return this.paymentMethod.type === Order.paymentType.PAYPAL
  }

  get isPaymentCredit () {
    const orderPayments = this.orderPayments
    return !isEmpty(orderPayments) && orderPayments.every(/** @type {Payment} */payment => payment.type === Order.paymentType.CREDIT)
  }

  get isFullCreditPayment () {
    return this.paymentMethod.type === Order.paymentType.CREDIT && this.isPaymentCredit
  }

  get isPaymentPartialCredit () {
    return this.paymentMethod.type !== Order.paymentType.CREDIT && this.orderPayments.some(/** @type {Payment} */payment => payment.type === Order.paymentType.CREDIT)
  }

  get shippingAddress () {
    if (this.shipping_address) {
      return new ShippingAddress(this.shipping_address)
    }
    return null
  }

  get billingAddress () {
    if (this.billing_address) {
      return new BillingAddress(this.billing_address)
    }
    return null
  }

  get accessKey () {
    return this.anonymous_access_key
  }

  get hasLotteryAvailable () {
    return this.has_lottery_available
  }

  get loyaltyDiscountTotal () {
    return this.loyalty_discount_total
  }
}

export default Order
