import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isObject from 'lodash/isObject'
import filter from 'lodash/filter'
import map from 'lodash/map'

import { findObject } from '@/services/Helpers'
import ProductAttribute from '@/database/models/ProductAttribute'
import ProductTag from '@/database/models/ProductTag'
import NavigationPage from '~/database/models/NavigationPage'
import ProductRepresentable from '~/database/ProductRepresentable'
import Medium from '@/structures/Medium'
import Article from '@/database/models/Article'
import { dayMonthYear, isTodayOrPast } from '@/services/DateService'

import GameExpansion from '@/database/models/GameExpansion'

class Product extends ProductRepresentable {
  static entity = 'products'
  static primaryKey = 'id'

  constructor (record) {
    super(record)

    const tagBase = filter(this.otherTags, tag => {
      return tag.isMiniatureBase
    })
    let baseType = null
    let baseTypeSize = null

    if (!isEmpty(tagBase)) {
      /** @type {ProductTag} */
      const baseTag = tagBase[0]
      baseType = baseTag.baseType
      baseTypeSize = baseTag.title
    }

    this.miniatureBase = baseType
    this.miniatureBaseSize = baseTypeSize

    this.articleObjects = map(this.articles, item => new Article(item))
  }

  static fields () {
    return {
      id: this.attr(1),
      additional_properties: this.attr({}),
      attributes: this.attr([]),
      breadcrumbs: this.attr([]),
      board_game_attributes: this.attr({}),
      bgg_id: this.attr(null),
      bgg_rating: this.attr(null),
      articles: this.attr([]),
      article_sku: this.attr([]),
      category_tag: this.attr(null),
      days_to_dispatch_from_supplier: this.attr(null),
      expansion: this.attr({}),
      enable_volume_pricing: this.attr(false),
      featured_on_najada: this.attr(false),
      image_url: this.attr(null), //
      image_url_back: this.attr(null), // Double-sided card optionally
      image_url_tiny: this.attr(null), // 100px
      image_url_small: this.attr(null), // 198px
      image_url_medium: this.attr(null), // 315px
      image_url_large: this.attr(null), // 1000px
      is_presale: this.attr(false),
      is_new: this.attr(false),
      localized_name: this.attr(null),
      name: this.attr(''),
      name_cz: this.attr(''),
      name_de: this.attr(''),
      description: this.attr(''),
      description_cz: this.attr(''),
      description_de: this.attr(''),
      short_description: this.attr(''),
      short_description_cz: this.attr(''),
      short_description_de: this.attr(''),
      other_tags: this.attr([]),
      rarity: this.attr(0),
      release_date: this.attr(null),
      secondary_media: this.attr([]),
      sku: this.attr(null),
      type: this.attr(null),
      related_products: this.attr([]),
      search_summary: this.attr({}),
      non_foil_exist: this.boolean(false),
      foil_exist: this.boolean(false),
    }
  }

  /**
   * @typedef {number} Product.Category
   * @enum {Product.Category}
   */
  static Category = {
    singleCards: 1,
    nonSingleCards: 2,
    singleCardsYGO: 3,
    singleCardsMtg: 4
  }

  static FractionTags = ['WH4fraction', 'WHSfraction', 'SWLfraction', 'SWXfraction']
  static RoleTags = ['WH4role']
  static UnitTypeTags = ['SWLunittype', 'WHSunittype', 'WH4unittype']

  static langMap = new Map([
    ['cs', 'cz'],
    ['en', 'en'],
    ['de', 'de']
  ])

  get foilExist() {
    return this.foil_exist
  }

  get nonFoilExist() {
    return this.non_foil_exist
  }

  get bothExistFoilNonFoil() {
    return this.foilExist && this.nonFoilExist
  }

  get searchSummary() {
    return this.search_summary
  }

  get allBreadcrumbs () {
    return map(this.breadcrumbs, breadcrumbsPart => {
      return map(breadcrumbsPart, part => new NavigationPage(part))
    })
  }

  get isNonSingle () {
    const type = this.type || ''
    return type.includes('nonsingle') || type.includes('yugiohsealedproduct')
  }

  get isVoucher () {
    const type = this.type || ''
    return type.includes('voucherproduct')
  }

  get hasFoilsOnly () {
    return this.articleObjects.every(article => article.isFoil)
  }

  get enableVolumePricing () {
    return this.enable_volume_pricing
  }

  get titleLoc() {
    return this.getLocWithDefault('name')
  }

  get image () {
    return this.image_url || 'https://d1avvq5q4v8v8k.cloudfront.net/static/stage/mkm-images/placeholder.png'
  }

  get imageTiny () {
    return this.image_url_tiny || this.imageSmall || this.image
  }

  get imageSmall () {
    return this.image_url_small || this.imageMedium || this.image
  }

  get imageMedium () {
    return this.image_url_medium || this.imageLarge || this.image
  }

  get imageLarge () {
    return this.image_url_large || this.image
  }

  get code () {
    return this.id
  }

  get otherTags () {
    return map(this.other_tags || [], item => new ProductTag(item))
  }

  get fractionTag () {
    return findObject(this.otherTags, tag => Product.FractionTags.includes(tag.tagTypeName))
  }

  get roleTag () {
    return findObject(this.otherTags, tag => Product.UnitTypeTags.includes(tag.tagTypeName))
  }

  get unitTypeTag () {
    return findObject(this.otherTags, tag => Product.RoleTags.includes(tag.tagTypeName))
  }

  get secondaryMedia () {
    return map(this.secondary_media || [], item => new Medium(item))
  }

  get isFlip () {
    return this.backImage !== null
  }

  get backImage() {
    return this.image_url_back
  }

  get additionalProperties () {
    return isObject(this.additional_properties) ? this.additional_properties : {}
  }

  get releaseDate () {
    return this.release_date
  }

  get releaseDateFormatted () {
    return dayMonthYear(this.releaseDate)
  }

  get faceManaCost () {
    return this.additionalProperties?.face?.mtg_mana_cost || null
  }

  get isFacePlanar() {
    const properties = this.additionalProperties
    const layout = properties.face?.layout || ''
    const displayType = properties.face?.mtg_display_type || ''
    return layout === 'planar' || displayType.startsWith('Battle')
  }

  get isPlanar() {
    const properties = this.additionalProperties
    const displayType = properties.mtg_display_type || ''
    const layout = properties.layout || ''
    return layout === 'planar' || displayType.startsWith('Battle')
  }

  get manaCost () {
    // {2}{G}, {2}{B}{B}
    return 'mtg_mana_cost' in this.additionalProperties ? this.additionalProperties.mtg_mana_cost : null
  }

  get displayType () {
    return 'mtg_display_type' in this.additionalProperties ? this.additionalProperties.mtg_display_type : null
  }

  get isOversized () {
    return 'is_oversized' in this.additionalProperties ? this.additionalProperties.is_oversized : null
  }

  get hasRarity () {
    return !isNull(this.rarity) && this.rarity !== 'No Rarity'
  }

  get isNew () {
    return this.is_new || false
  }

  get isPresale () {
    const releaseDateCondition = this.releaseDate && !isTodayOrPast(this.releaseDate)
    return this.is_presale || releaseDateCondition || false
  }

  get expansionObject () {
    return new GameExpansion(this.expansion || {})
  }

  get expansionTitle () {
    return this.expansionObject.titleLoc
  }

  get expansionTitleLoc () {
    return this.expansionObject.titleLoc
  }

  get expansionShortCode () {
    return this.expansionObject.shortCode
  }

  get expansionIcon () {
    return this.expansionObject.iconUrl
  }

  get expansionId () {
    return this.expansionObject.id
  }

  get expansionBuylistLanguages () {
    return this.expansionObject.buyListLanguages
  }

  get isBoardGame () {
    return this.type === 'wizardshop_suite.boardgameproduct'
  }

  get isMiniature () {
    return this.type === 'wizardshop_suite.miniatureproduct'
  }

  get isMtgSingleCard () {
    return this.type === 'wizardshop_suite.mtgsinglecardproduct'
  }

  get isGenericSingleCard () {
    return this.type === 'wizardshop_suite.singlecardproduct'
  }

  get relatedProducts () {
    return map(this.related_products || [], item => new Product(item))
  }

  get relatedArticles () {
    const productsWithArticles = filter(this.related_products || [], product => {
      return product.articles.length > 0
    })

    return map(productsWithArticles, product => {
      const article = new Article(product.articles[0])
      article.product = product
      return article
    })
  }

  // ATTRIBUTES

  get attributeObjects () {
    return map(this.attributes, attr => new ProductAttribute(attr))
  }

  // BOARD GAMES

  get boardGameAttributes () {
    return this.board_game_attributes || {}
  }

  get bggRating () {
    let value = this.bgg_rating

    if (value) {
      value = Math.round(value)
    }

    return value
  }

  get bggId () {
    return this.bgg_id
  }

  get bbgMinPlayers () {
    return this.getBBGAttribute('min_players')
  }

  get bbgMaxPlayers () {
    return this.getBBGAttribute('max_players')
  }

  get bbgMinPlaytime () {
    return this.getBBGAttribute('min_playtime')
  }

  get bbgMaxPlaytime () {
    return this.getBBGAttribute('max_playtime')
  }

  getBBGAttribute (attr) {
    let value = null

    if (attr in this.boardGameAttributes) {
      value = this.boardGameAttributes[attr]
    }

    return value
  }

  getTitle (lang = 'cs') {
    const langToUse = Product.langMap.get(lang)
    let title = this.name

    if (langToUse !== 'en') {
      title = this[`name_${langToUse}`] !== '' ? this[`name_${langToUse}`] : title
    }

    return title
  }

  getDescription (lang = 'cs') {
    const langToUse = Product.langMap.get(lang)
    let description = this.description

    if (langToUse !== 'en') {
      description = this[`description_${langToUse}`]
    }

    return description
  }

  getShortDescription (lang = 'cs') {
    const langToUse = Product.langMap.get(lang)
    let description = this.short_description

    if (langToUse !== 'en') {
      description = this[`short_description_${langToUse}`]
    }

    return description
  }
}

export default Product
