import Address from '@/database/models/Address'

class ShippingAddress extends Address {
  static entity = 'shippingAddresses'

  static najadaAddress = new ShippingAddress({
    first_name: 'najada_address_name',
    street: 'najada_address_street',
    city: 'city_prague',
    zip_code: '13000',
    country: 'CZ'
  })

  static najadaAddressNoDiacritics = new ShippingAddress({
    first_name: 'Najada',
    street: 'Ondrickova 2166/14',
    city: 'Prague',
    zip_code: '13000',
    country: 'Czech Republic'
  })
}

export default ShippingAddress
