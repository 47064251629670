import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import map from 'lodash/map'

import AutoCodableN from '~/database/models/AutoCodableN'
import { getLoc } from '~/structures/Localizable'

export default class ProductAttribute extends AutoCodableN {
  static fields () {
    return {
      id: this.attr(1),
      param: this.attr({}),
      value: this.attr(null),
      value_int: this.attr(null),
      param_items: this.attr([])
    }
  }

  static propertyMap = new Map([
    ['valueInt', 'value_int'],
    ['paramItems', 'param_items']
  ])

  static langMap = new Map([
    ['cs', 'cz'],
    ['en', 'en'],
    ['de', 'de']
  ])

  get isString () {
    return !isNull(this.value)
  }

  get isInt () {
    return !isNull(this.value_int)
  }

  get isOption () {
    return !isEmpty(this.param_items)
  }

  get paramId () {
    return this.param.id
  }

  get mainValueLoc () {
    const paramItemTitleLoc = this.paramItemTitleLoc
    const value = getLoc(null, null, null)
    value.en = this.value || this.valueInt || paramItemTitleLoc.en || null
    value.cs = this.value || this.valueInt || paramItemTitleLoc.cs || null
    value.de = this.value || this.valueInt || paramItemTitleLoc.de || null
    return value
  }

  get paramTitleLoc () {
    const value = getLoc(null, null, null)

    if (this.param) {
      value.en = this.param.name
      value.cs = this.param.name_cz
      value.de = this.param.name_de
    }

    return value
  }

  get paramItemTitleLoc () {
    const value = getLoc(null, null ,null)

    if (this.paramItems) {
      value.en = map(this.paramItems, 'value').join(', ')
      value.cs = map(this.paramItems, 'value_cz').join(', ')
      value.de = map(this.paramItems, 'value_de').join(', ')
    }

    return value
  }
}
