import AutoCodableN from '~/database/models/AutoCodableN'
import GCGame from "~/database/models/GCGame";

export default class GCFormat extends AutoCodableN {
  static fields () {
    return {
      name: this.attr(null),
      game: this.attr(null),
      event_link_id: this.attr(null),
    }
  }

  get id() {
    return this.name
  }

  get gameObject() {
    return new GCGame(this.game || {})
  }

  get gameId() {
    return this.gameObject.id
  }

  get eventLinkId() {
    return this.event_link_id
  }
}
