import AutoCodableN from '~/database/models/AutoCodableN'

export default class CMSTag extends AutoCodableN {
  static fields () {
    return {
      value: this.attr(null),
      value_cz: this.attr(null),
      article_count: this.attr(0)
    }
  }

  static propertyMap = new Map([
    ['count', 'article_count']
  ])

  get id () {
    return this.value
  }

  get label () {
    return this.value
  }

  get labelLoc () {
    return this.getLocWithDefault('value')
  }

  get icon () {
    return null
  }
}
