import CartItem from '@/database/models/CartItem'

export default class BuyCartItem extends CartItem {
  static entity = 'buycartitems'
  static primaryKey = 'id'

  static fields () {
    return {
      ...CartItem.fields(),
      offer: this.attr(null),
      original_count: this.attr(0),
      original_effective_unit_price_czk: this.attr(0),
      original_effective_unit_price_eur: this.attr(0),
    }
  }

  get originalEffectiveUnitPriceLoc() {
    return this.getLocCurrency('original_effective_unit_price')
  }

  get originalCount() {
    return this.original_count
  }

  get countHasChanged() {
    return this.count !== this.originalCount && this.originalCount !== null
  }

  get priceHasChanged() {
    return this.effectiveUnitPriceLoc.czk !== this.originalEffectiveUnitPriceLoc.czk && this.originalEffectiveUnitPriceLoc.czk !== null
  }

  get priceHasDropped() {
    return this.effectiveUnitPriceLoc.czk < this.originalEffectiveUnitPriceLoc.czk
  }
}
