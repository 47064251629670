import isNull from 'lodash/isNull'
import Article, { generateBubbleContent, generateBubbleType } from '~/database/models/Article'
import BuyupOffer from '~/database/models/BuyupOffer'

import ProductRepresentable from '~/database/ProductRepresentable'

import { getLocCurrency } from '~/structures/Localizable'

class CartItem extends ProductRepresentable {
  static entity = 'cartitems'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      order: this.attr(0),
      cart: this.attr(1),
      count: this.attr(0),
      article: this.attr(null),
      offer: this.attr(null),
      language: this.attr(null),
      base_unit_price_czk: this.number(0),
      base_unit_price_eur: this.number(0),
      effective_unit_price_czk: this.number(0),
      effective_unit_price_eur: this.number(0),
      discount_type_czk: this.string('NONE'),
      discount_type_eur: this.string('NONE'),
      condition: this.attr('NM'),
      reserved_in_shop: this.number(false),
      reserved_in_warehouse: this.number(false)
    }
  }

  static DiscountType = {
    NONE: 'NONE',
    REGULAR: 'REGULAR',
    VOLUME_PRICING: 'VOLUME_PRICING',
    VOUCHER: 'VOUCHER',
    LOYALTY: 'LOYALTY'
  }

  get sku() {
    return this.hasArticle ? this.articleModel.sku : this.offerModel.product?.sku
  }

  get immediatelyAvailable () {
    let total = this.count

    if (!isNull(this.article)) {
      total += this.articleModel.immediatelyAvailable
    }

    return total
  }

  get hasArticle() {
    return !isNull(this.article)
  }

  get hasOffer() {
    return !isNull(this.offer)
  }

  get articleModel () {
    return new Article(this.article)
  }

  get offerModel () {
    return new BuyupOffer(this.offer)
  }

  get title () {
    if (this.hasArticle) {
      return this.articleModel.title
    }
    if (this.hasOffer) {
      return this.offerModel.title
    }
    return null
  }

  get titleLoc () {
    if (this.hasArticle) {
      return this.articleModel.titleLoc
    }
    if (this.hasOffer) {
      return this.offerModel.titleLoc
    }
    return null
  }

  get descriptionPartsLoc () {
    if (this.isNonSingle) return null

    let articleObject = {}

    if(this.hasOffer) {
      articleObject = this.offerModel
    } else if (this.hasArticle) {
      articleObject = this.articleModel
    }

    return 'descriptionPartsLoc' in articleObject ? articleObject.descriptionPartsLoc : {}
  }

  get isYgoFirstEd () {
    if (this.hasArticle) {
      return this.articleModel.isYgoFirstEdition
    }

    return false
  }

  get isUnpackedPackaging () {
    let val = false

    if(this.hasArticle) {
      val = this.articleModel.isUnpackedPackaging
    }

    return val
  }

  get isDamagedPackaging () {
    let val = false

    if(this.hasArticle) {
      val = this.articleModel.isDamagedPackaging
    }

    return val
  }

  get expansion () {
    if (this.hasArticle) {
      return this.articleModel.expansionShortCode
    }
    if (this.hasOffer) {
      return this.offerModel.expansionShortCode
    }
    return null
  }

  get expansionId () {
    if (this.hasArticle) {
      return this.articleModel.expansionId
    }
    if (this.hasOffer) {
      return this.offerModel.expansionId
    }
    return null
  }

  get expansionFullNameLoc () {
    if (this.hasArticle) {
      return this.articleModel.expansionTitleLoc
    }
    if (this.hasOffer) {
      return this.offerModel.expansionTitleLoc
    }

    return null
  }

  get isFoil () {
    if (this.hasArticle) {
      return this.articleModel.isFoil
    }
    if (this.hasOffer) {
      return this.offerModel.isFoil
    }
    return null
  }

  get isOversized () {
    if (this.hasArticle) {
      return this.articleModel.isOversized
    }
    if (this.hasOffer) {
      return this.offerModel.isOversized
    }
    return false
  }

  get isNonSingle () {
    if (this.hasArticle) {
      return this.articleModel.isNonSingle
    }
    if (this.hasOffer) {
      return this.offerModel.isNonSingle
    }
    return false
  }

  get isMtgSingleCard () {
    if (this.hasArticle) {
      return this.articleModel.isMtgSingleCard
    }

    return false
  }

  get hiddenLanguage () {
    let hidden = false

    if (this.hasArticle) {
      hidden = this.articleModel.hiddenLanguage
    }

    return hidden
  }

  get languageCode () {
    if (this.hasArticle) {
      return this.articleModel.languageCode
    }
    if (!isNull(this.language)) {
      return this.language.toUpperCase()
    }

    return null
  }

  get image () {
    if (this.hasArticle) {
      return this.articleModel.image
    }
    if (this.hasOffer) {
      return this.offerModel.image
    }
    return null
  }

  get isDiscounted () {
    return this.effectiveUnitPriceLoc.czk !== this.baseUnitPriceLoc.czk
  }

  get totalPriceLoc() {
    return getLocCurrency(this.effective_unit_price_czk * this.count, this.effective_unit_price_eur * this.count)
  }

  get baseUnitPriceLoc() {
    return this.getLocCurrency('base_unit_price')
  }

  get effectiveUnitPriceLoc() {
    return this.getLocCurrency('effective_unit_price')
  }

  get discountTypeLoc() {
    // NONE, REGULAR, VOLUME_PRICING, VOUCHER, LOYALTY
    return getLocCurrency(this.discount_type_czk, this.discount_type_eur)
  }

  get bubbleType () {
    return generateBubbleType(
      this.isDiscounted,
      this.articleModel.isPresale,
      this.articleModel.isPromo,
      this.articleModel.isNew
    )
  }

  get bubbleContent () {
    return generateBubbleContent(
      this.isDiscounted,
      this.baseUnitPriceLoc.czk,
      this.effectiveUnitPriceLoc.czk,
      this.articleModel.isPresale,
      this.articleModel.releaseDateFormatted,
      this.articleModel.isPromo,
      this.articleModel.isNew
    )
  }
}

export default CartItem
