import isEmpty from 'lodash/isEmpty'
import AutoCodableN from '~/database/models/AutoCodableN';
import GCPlayerProfile from '~/database/models/GCPlayerProfile';
import GCTeamTournament from '~/database/models/GCTeamTournament'
import {getLoc} from '~/structures/Localizable'

export default class GCStanding extends AutoCodableN {
  constructor(props) {
    super(props);

    this.previousSeasonStandingNameLoc = getLoc(this.previous_season_standing_name_en, this.previous_season_standing_name_cz)
    this.playerProfileObject = new GCPlayerProfile(props.player_profile || {})
    this.teamTournamentObject = new GCTeamTournament(props.team_tournament || {})
  }

  static fields() {
    return {
      season: this.attr(null),
      format: this.attr(null),
      player_profile: this.attr(null),
      team_tournament: this.attr(null),
      league_points: this.attr(0),
      rank: this.attr(1),
      standing: this.attr(null),
      current_standing: this.attr(1),
      previous_standing: this.attr(1000),
      wins: this.attr(null),
      losses: this.attr(null),
      draws: this.attr(null),
      match_points: this.attr(null),
      max_points: this.attr(null),
      matches_played: this.attr(null),
      tournament_played: this.attr(null),
      match_win_percentage: this.attr(null),
      opponent_match_win_percentage: this.attr(null),
      game_win_percentage: this.attr(null),
      opponent_game_win_percentage: this.attr(null),
      previous_season_standing_name_cz: this.attr(null),
      previous_season_standing_name_en: this.attr(null),
      previous_season_standing_current_standing: this.attr(null),
    }
  }

  get hasPreviousSeasonStanding () {
    return this.previousSeasonStandingCurrentStanding !== null
  }

  /**
   *
   * @return {number}
   */
  get previousSeasonStandingCurrentStanding () {
    return this.previous_season_standing_current_standing
  }

  get hasAnyAchievements() {
    return this.playerProfileObject.hasAnyAchievements
  }

  get isPublicStats() {
    return this.hasPlayerProfile ? this.playerProfileObject.isPublicStats : this.teamTournamentObject.isPublicStats
  }

  get omw() {
    return this.opponent_match_win_percentage
  }

  get omwRounded() {
    return Math.round(this.omw)
  }

  get gw() {
    return this.game_win_percentage
  }

  get gwRounded() {
    return Math.round(this.gw)
  }

  get ogw() {
    return this.opponent_game_win_percentage
  }

  get ogwRounded() {
    return Math.round(this.ogw)
  }

  get teamName() {
    return this.teamTournamentObject.teamName
  }

  get id() {
    let id = 1

    if(this.hasPlayerProfile) {
      id = this.playerProfileObject?.id
    } else if (this.hasTeamTournament) {
      id = this.teamTournamentObject?.playerId
    }

    return id
  }

  get key() {
    return [this.id, this.format, this.season].join('-')
  }

  get hasPlayerProfile() {
    return this.player_profile !== null
  }

  get hasTeamTournament() {
    return this.team_tournament !== null
  }

  get lastname() {
    return this.playerProfileObject.lastname
  }

  get fullName() {
    return this.playerProfileObject.fullName
  }

  get shortenedName() {
    let value = this.playerProfileObject.shortenedName

    if(isEmpty(value)) {
      value = this.teamTournamentObject.teamName
    }

    return value
  }

  get avatar() {
    return this.playerProfileObject.avatar
  }

  get teamAvatar() {
    return this.teamTournamentObject.avatar
  }

  get gameId() {
    return this.playerProfileObject.gameObject.id
  }

  get leaguePoints() {
    return this.league_points
  }

  get maxPoints() {
    return this.max_points
  }

  get matchPoints() {
    return this.match_points || 0
  }

  get tournamentPlayed() {
    return this.tournament_played
  }

  get matchesPlayed() {
    return this.matches_played
  }

  get matchWinPercentage() {
    return Math.round(this.match_win_percentage)
  }

  get currentStanding() {
    return this.current_standing
  }

  get previousStanding() {
    return this.previous_standing
  }

  get hasStandingChange() {
    return this.currentStanding !== this.previousStanding && this.previousStanding !== null
  }

  get standingChange() {
    const prevStanding = this.previousStanding || this.currentStanding
    return prevStanding - this.currentStanding
  }
}
