import map from 'lodash/map';
import AutoCodableN from '~/database/models/AutoCodableN'
import {getLoc} from '~/structures/Localizable';
import GCGame from '~/database/models/GCGame';
import GCFormat from '~/database/models/GCFormat';

export default class GCLeague extends AutoCodableN {
  constructor(props) {
    super(props);

    this.gameObject = new GCGame(this.game || {})
    this.formatObjects = map(this.formats, format => new GCFormat(format))
    this.nameLoc = getLoc(this.name_en, this.name_cz)
    this.descriptionLoc = getLoc(this.description_en, this.description_cz)
    this.rulesLoc = getLoc(this.rules_en, this.rules_cz)
  }

  static fields () {
    return {
      id: this.attr(1),
      name_cz: this.attr(null),
      name_en: this.attr(null),
      description_cz: this.attr(null),
      description_en: this.attr(null),
      rules_cz: this.attr(null),
      rules_en: this.attr(null),
      game: this.attr(null),
      formats: this.attr([]),
      points_enroll: this.attr(null),
      points_win: this.attr(null),
      points_draw: this.attr(null)
    }
  }

  get pointsEnroll () {
    return this.points_enroll
  }

  get pointsWin () {
    return this.points_win
  }

  get pointsDraw () {
    return this.points_draw
  }
}
