import CMSArticle from '@/database/models/CMSArticle'

class CMSCareerArticle extends CMSArticle {
  static entity = 'cmscareerarticles'
  static primaryKey = 'id'

  static additionalProperties = [
    'work_location',
    'work_relation_type',
    'contract_relation_type',
    'work_relation_duration',
    'benefits',
    'education_required',
    'languages_required',
    'contact_person_name',
    'contact_person_email',
    'contact_person_phone'
  ]

  get careerAdditionalProperties () {
    return CMSCareerArticle.additionalProperties
  }
}

export default CMSCareerArticle
