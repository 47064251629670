import Codable from '~/database/models/Codable'

/**
 * Codable protocol which generates getters and setters based on static propertyMap variable.
 * Is memory light alternative of AutoCodable class
 */
class AutoCodableN extends Codable {
  constructor (record) {
    super(record)

    const oThis = this
    const map = this.constructor.propertyMap

    map.forEach((value, key) => {
      if (map.has(key)) {
        Object.defineProperty(this, key, {
          get () {
            return oThis[map.get(key)]
          },
          set (value) {
            oThis[map.get(key)] = value
          }
        })
      }
    })
  }

  updateModel() {}
}

export default AutoCodableN
