import Cart from '@/database/models/Cart'

export default class SellCart extends Cart {
  static entity = 'sellcarts'
  static primaryKey = 'id'

  static fields () {
    return {
      ...Cart.fields(),
      items: this.attr([])
    }
  }
}
