import AutoCodableN from '~/database/models/AutoCodableN'
import GCTeam from '~/database/models/GCTeam'

export default class GCTeamTournament extends AutoCodableN {

  constructor(props) {
    super(props)

    this.teamObject = new GCTeam(props.team || {})
  }

  static fields() {
    return {
      id: this.attr(null),
      team: this.attr(null)
    }
  }

  get teamName() {
    return this.teamObject.fullNames
  }

  get avatar() {
    return this.teamObject.avatar
  }

  get isPublicStats() {
    return this.teamObject.isPublicStats
  }

  get playerId() {
    return this.teamObject.playerId
  }
}
