import FilterConfig from '~/structures/filters/FilterConfig'
import AutoCodableN from '~/database/models/AutoCodableN'

export default class GameExpansion extends AutoCodableN {
  static entity = 'game_expansions'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      localized_name: this.attr(null),
      name: this.attr(null),
      name_cz: this.attr(null),
      name_de: this.attr(null),

      release_date: this.attr(null),
      appears_in_najada_filter: this.attr(false),
      game: this.attr(null),
      icon_url: this.attr(null),
      najada_banner_foreground: this.attr(null),
      najada_banner_background: this.attr(null),
      najada_featured: this.attr(false),
      short_code: this.attr(null),
      types: this.attr([]),
      buy_list_languages: this.attr([])
    }
  }

  static propertyMap = new Map([
    ['localizedName', 'localized_name'],
    ['appearsInNajadaFilter', 'appears_in_najada_filter'],
    ['iconUrl', 'icon_url'],
    ['najadaFeatured', 'najada_featured'],
    ['najadaBannerForeground', 'najada_banner_foreground'],
    ['najadaBannerBackground', 'najada_banner_background'],
    ['shortCode', 'short_code'],
    ['releaseDate', 'release_date'],
    ['buyListLanguages', 'buy_list_languages']
  ])

  static isObjectMtgGame = function (item) {
    return item.game === FilterConfig.MTGGame
  }

  get titleLoc () {
    return this.getLocWithDefault('name', this.localizedName)
  }

  get labelLoc () {
    return this.titleLoc
  }

  get icon () {
    return this.iconUrl
  }

  get isMtg () {
    return this.game === FilterConfig.MTGGame
  }
}
