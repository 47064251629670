import AutoCodableN from '~/database/models/AutoCodableN'

export default class GCGame extends AutoCodableN {
  static fields () {
    return {
      name: this.attr(null),
      internal_name: this.attr(null),
      logo: this.attr(null),
      order: this.attr(1)
    }
  }

  get id() {
    return this.internalName
  }

  get internalName() {
    return this.internal_name
  }
}
