import isObject from 'lodash/isObject'

export default class StoreModel {
  constructor (props = {}) {
    let propsObject = props
    if(!isObject(props)) {
      propsObject = {}
    }

    const fields = this.constructor.fields()

    for (const field in fields) {
      if(field in propsObject) {
        fields[field] = propsObject[field]
      }
    }

    Object.assign(this, fields)
  }

  static fields () {
    return {}
  }

  static attr(value) {
    return value
  }

  static string(value) {
    return value
  }

  static number(value) {
    return value
  }

  static boolean(value) {
    return value
  }
}
