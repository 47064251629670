import isNumber from 'lodash/isNumber'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import isNull from 'lodash/isNull'
import each from 'lodash/each'

import { findObject, langMap } from '@/services/Helpers'
import { dayMonth, dayMonthYear } from '@/services/DateService'
import ProductRepresentable from '~/database/ProductRepresentable'
import Product from '@/database/models/Product'
import Medium from '@/structures/Medium'

import EstimateDeliveryMethod from '@/structures/EstimateDeliveryMethod'
import { titleSKU } from '~/services/NavigationService'
import { getLoc } from '~/structures/Localizable'

class Article extends ProductRepresentable {
  static entity = 'articles'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      additional_properties: this.attr({}),
      damaged_description: this.attr(null),
      damaged_description_cz: this.attr(null),
      damaged_description_de: this.attr(null),
      allowed_on_najada: this.attr(true),
      sku: this.attr(null),
      type: this.attr(null),
      language_code: this.attr('EN'),
      hide_language: this.boolean(false),
      condition: this.attr('NM'),
      is_presale: this.attr(false),
      immediate_availability: this.attr(0),
      featured_discount: this.attr(false),
      effective_price_czk: this.number(null),
      effective_price_eur: this.number(null),
      regular_price_czk: this.number(null),
      regular_price_eur: this.number(null),
      price_currency: this.attr('CZK'),
      image_url: this.attr(null),
      image_url_small: this.attr(null),
      secondary_media: this.attr([]),
      total_availability: this.attr(0),
      per_user_limit: this.attr(0),
      orderable_from_supplier: this.attr(false),
      product: this.attr({}),
      volume_pricing: this.attr([]),
      estimate_delivery_country: this.attr(null),
      estimate_delivery_method: this.attr([]),
      rating: this.attr(null),
      user_rating: this.attr(null),
      wotc_sku: this.attr(null)
    }
  }

  static stockSplitValues = [4, 8, 12, 20, 36, 100]

  static AttributeId = {
    difficulty: 1,
    minPlayers: 2,
    maxPlayers: 3,
    minPlaytime: 4,
    minAge: 5,
    author: 6,
    distributor: 7,
    contents: 7,
    modelCount: 9,
    maxPlaytime: 10,
    rulesLanguage: 11,
    publisher: 12,
    bggRating: 13
  }

  get wotcSku() {
    return this.wotc_sku
  }

  set wotcSku(value) {
    this.wotc_sku = value
  }

  get wotcSkus() {
    return this.wotcSku.split(';')
  }

  get hasWotcSku() {
    return this.wotcSku !== null
  }

  get allBreadcrumbs () {
    return this.productObject.allBreadcrumbs || []
  }

  get foilExist() {
    return this.productObject.foilExist
  }

  get nonFoilExist() {
    return this.productObject.nonFoilExist
  }

  get bothExistFoilNonFoil() {
    return this.productObject.bothExistFoilNonFoil
  }

  get titleSKULoc () {
    const titleLoc = this.titleLoc
    const sku = this.sku
    return getLoc(titleSKU(titleLoc.en, sku), titleSKU(titleLoc.cs, sku), titleSKU(titleLoc.de, sku))
  }

  get isAllowedOnNajada () {
    return this.allowed_on_najada
  }

  get isRealArticle() {
    return this.id !== 1
  }

  get damagedDescriptionLoc () {
    return this.getLocWithDefault('damaged_description')
  }

  get hasDamagedDescription() {
    return !isEmpty(this.damagedDescriptionLoc.cs)
  }

  get productObject () {
    return new Product(this.product)
  }

  get isNonSingle () {
    return this.productObject.isNonSingle || this.isBoardGame || this.isMiniature || this.isVoucher
  }

  get isBoardGame () {
    return this.productObject.isBoardGame
  }

  get isMiniature () {
    return this.productObject.isMiniature
  }

  get isMtgSingleCard () {
    return this.productObject.isMtgSingleCard
  }

  get isVoucher () {
    return this.productObject.isVoucher
  }

  get otherTags () {
    return this.productObject.otherTags
  }

  get fractionTag () {
    return this.productObject.fractionTag
  }

  get roleTag () {
    return this.productObject.roleTag
  }

  get unitTypeTag () {
    return this.productObject.unitTypeTag
  }

  get titleLoc () {
    return this.productObject.titleLoc
  }

  get descriptionPartsLoc () {
    const items = []
    const expansionTitleLoc = this.expansionTitleLoc
    if (this.isFoil) items.push('Foil')
    if (this.condition) items.push(this.condition)

    const descriptionLoc = {
      en: [...items],
      cs: [...items],
      de: [...items],
    }
    each(Object.keys(expansionTitleLoc), lang => {
      if(!isNull(expansionTitleLoc[lang]) && !isEmpty(expansionTitleLoc[lang])) {
        descriptionLoc[lang].unshift(expansionTitleLoc[lang])
      }
    })

    return getLoc(descriptionLoc.en.join(', '), descriptionLoc.cs.join(', '), descriptionLoc.de.join(', '))
  }

  get image () {
    return this.image_url || this.productObject.image
  }

  get imageSmall () {
    return this.image_url_small || this.productObject.imageSmall || this.image
  }

  get imageMedium () {
    return this.productObject.imageMedium
  }

  get imageLarge () {
    return this.productObject.imageLarge
  }

  get images () {
    return this.secondaryMedia
  }

  get secondaryMedia () {
    return map([...(this.secondary_media || []), ...(this.productObject.secondaryMedia || [])], medium => {
      return new Medium(medium)
    })
  }

  get combinedMedia () {
    const mainImageMedium = new Medium({
      type: Medium.Type.image,
      url: this.imageMedium
    })
    return [mainImageMedium].concat(this.secondaryMedia)
  }

  get hasRating () {
    return !isNull(this.rating)
  }

  get ratingPoints () {
    return parseFloat(this.rating)
  }

  get hasUserRating () {
    return !isNull(this.userRating)
  }

  get userRating () {
    return this.user_rating
  }

  get expansionTitle () {
    return this.productObject.expansionTitle
  }

  get expansionTitleLoc () {
    return this.productObject.expansionTitleLoc
  }

  get expansionShortCode () {
    return this.productObject.expansionShortCode
  }

  get expansionId () {
    return this.productObject.expansionObject.id
  }

  get expansionIcon () {
    return this.productObject.expansionObject.icon
  }

  get attributeObjects () {
    return this.productObject.attributeObjects
  }

  get attributeDifficulty () {
    return this.getAttributeLoc(Article.AttributeId.difficulty, this.attributeObjects)
  }

  get attributeMinPlayersNumber () {
    return this.getAttributeLoc(Article.AttributeId.minPlayers, this.attributeObjects).en
  }

  get attributeMaxPlayersNumber () {
    return this.getAttributeLoc(Article.AttributeId.maxPlayers, this.attributeObjects).en
  }

  get attributePlayersNumber () {
    const minPlayers = this.attributeMinPlayersNumber
    const maxPlayers = this.attributeMaxPlayersNumber
    let value = minPlayers || maxPlayers || null

    if (minPlayers && maxPlayers) {
      value = `${minPlayers} - ${maxPlayers}`

      if (minPlayers === maxPlayers) {
        value = minPlayers
      }
    } else if (minPlayers) {
      value = `${minPlayers}+`
    }

    return value
  }

  get attributePlaytime () {
    const minTime = this.attributeMinPlaytime
    const maxTime = this.attributeMaxPlaytime
    let value = minTime || maxTime || null

    if (minTime && maxTime) {
      value = `${minTime} - ${maxTime} min`
    } else if (value) {
      value = `${value} min`
    }

    return value
  }

  get attributePlaytimeAverage () {
    const minTime = this.attributeMinPlaytime
    const maxTime = this.attributeMaxPlaytime || minTime
    let value = (minTime + maxTime) / 2
    value = Math.round(value / 10) * 10

    return value
  }

  get attributeMinPlaytime () {
    return this.getAttributeLoc(Article.AttributeId.minPlaytime, this.attributeObjects).en
  }

  get attributeMaxPlaytime () {
    return this.getAttributeLoc(Article.AttributeId.maxPlaytime, this.attributeObjects).en
  }

  get attributeSize () {
    return this.productObject.miniatureBaseSize
  }

  get attributeSizeType () {
    return this.productObject.miniatureBase
  }

  get attributeFiguresCount () {
    return this.getAttributeLoc(Article.AttributeId.modelCount, this.attributeObjects)
  }

  get bggId () {
    return this.productObject.bggId
  }

  get bggLink () {
    const bggId = this.bggId
    let link = null

    if(bggId) {
      link = `https://boardgamegeek.com/boardgame/${bggId}`
    }

    return link
  }

  get bggRating () {
    return this.productObject.bggRating
  }

  get hasBggRating () {
    const value = this.bggRating
    return value && value > 0
  }

  get bbgMinPlayers () {
    return this.productObject.bbgMinPlayers
  }

  get bbgMaxPlayers () {
    return this.productObject.bbgMaxPlayers
  }

  get bbgMinPlaytime () {
    return this.productObject.bbgMaxPlaytime
  }

  get bbgMaxPlaytime () {
    return this.productObject.bbgMaxPlaytime
  }

  get bubbleType () {
    return generateBubbleType(
      this.isDiscounted,
      this.isPresale,
      this.isPromo && this.isDiscounted,
      this.isNew
    )
  }

  get bubbleContent () {
    return generateBubbleContent(
      this.isDiscounted,
      this.regularPriceLoc.czk,
      this.priceLoc.czk,
      this.isPresale,
      this.releaseDateFormatted,
      this.isPromo && this.isDiscounted,
      this.isNew)
  }

  get isPromo () {
    return this.featured_discount || false
  }

  get isDiscounted () {
    return this.regularPriceLoc.czk !== this.effectiveUnitPriceLoc.czk
  }

  get isNew () {
    return this.productObject ? this.productObject.isNew : false
  }

  get regularPriceLoc() {
    return this.getLocCurrency('regular_price')
  }

  get priceLoc() {
    return this.getLocCurrency('effective_price')
  }

  get baseUnitPriceLoc () {
    return this.regularPriceLoc
  }

  get effectiveUnitPriceLoc () {
    return this.priceLoc
  }

  get isPresale () {
    return this.productObject ? this.productObject.isPresale : false
  }

  get isOversized () {
    const articleValue = 'is_oversized' in this.additional_properties && this.additional_properties.is_oversized
    const productValue = this.productObject ? this.productObject.isOversized : false
    return articleValue || productValue
  }

  get isFoil () {
    return 'is_foil' in this.additional_properties && this.additional_properties.is_foil
  }

  get isUnpackedPackaging () {
    return 'unpacked_packaging' in this.additional_properties && this.additional_properties.unpacked_packaging
  }

  get isDamagedPackaging () {
    return 'damaged_packaging' in this.additional_properties && this.additional_properties.damaged_packaging
  }

  get isAlpha () {
    return this.isAltered
  }

  get isSigned () {
    return 'is_signed' in this.additional_properties && this.additional_properties.is_signed
  }

  get isAltered () {
    return 'is_altered' in this.additional_properties && this.additional_properties.is_altered
  }

  get isFirstEdition () {
    return 'is_first_ed' in this.additional_properties && this.additional_properties.is_first_ed
  }

  get isYgoFirstEdition () {
    return 'ygo_first_ed' in this.additional_properties && this.additional_properties.ygo_first_ed
  }

  get hiddenLanguage () {
    return this.hide_language
  }

  get languageCode () {
    return this.language_code
  }

  get isEnglish () {
    return this.languageCode === 'EN'
  }

  get code () {
    return this.id
  }

  get releaseDate () {
    return this.productObject.releaseDate
  }

  get releaseDateFormatted () {
    return dayMonth(this.productObject.releaseDate)
  }

  get releaseDateFull () {
    return dayMonthYear(this.productObject.releaseDate)
  }

  get reviewCount () {
    return 12
  }

  get stockItems () {
    const totalAvailability = Math.max(0, this.total_availability)
    return Math.min(totalAvailability, this.perUserLimit || totalAvailability)
  }

  get perUserLimit() {
    return this.per_user_limit
  }

  get hasPerUserLimit() {
    const limit = this.perUserLimit
    return isNumber(limit) && limit > 0
  }

  get numberOfItemsAvailable () {
    return this.stockItems
  }

  get richContentStock () {
    let status = 'https://schema.org/InStock'

    if (this.isPresale) {
      status = 'https://schema.org/PreSale'
    } else if (this.numberOfItemsAvailable < 1) {
      status = 'https://schema.org/OutOfStock'
    }

    return status
  }

  get isPurchasable () {
    return this.numberOfItemsAvailable > 0
  }

  get volumePricings () {
    return sortBy(this.volume_pricing || [], ['minimum_purchased'])
  }

  get hasVolumePricing () {
    return this.volumePricings.length > 0
  }

  get enableVolumePricing () {
    return this.productObject.enableVolumePricing
  }

  get isOrderableFromSupplier () {
    return this.orderable_from_supplier
  }

  get immediateAvailability () {
    return this.immediate_availability
  }

  get isImmediatelyAvailable () {
    return this.immediateAvailability > 0
  }

  get status () {
    // available, unavailable, unavailable_temporarily
    return this.stockItems > 0 ? 'available' : 'unavailable'
  }

  get estimateDeliveryCountry () {
    return this.estimate_delivery_country
  }

  get estimateDeliveryMethods () {
    return map(this.estimate_delivery_method || [], item => new EstimateDeliveryMethod(item))
  }

  get hasEstimateDeliveryMethods () {
    return !isEmpty(this.estimateDeliveryMethods)
  }

  get relatedProducts () {
    return this.productObject.relatedProducts
  }

  get relatedArticles () {
    return this.productObject.relatedArticles
  }

  getReviewCountForLevel (level) {
    return level
  }

  /**
   * Find volume price for article based on piece-count
   *
   * @param numberOfPieces
   * @returns {Number}
   */
  getPriceForPieces (numberOfPieces, currency) {
    const effectivePrice = this.effectiveUnitPriceLoc[currency]
    const vp = this.volumePricings.reverse()

    if (this.productObject.enableVolumePricing) {
      for (let i = 0; i < vp.length; i++) {
        const item = vp[i]
        if (numberOfPieces >= item.minimum_purchased) {
          return item[`effective_price_${currency}`] || effectivePrice
        }
      }
    }

    return effectivePrice
  }

  getDescription (lang) {
    return this.productObject.getDescription(lang)
  }

  getShortDescription (lang) {
    return this.productObject.getShortDescription(lang)
  }

  getTitle (lang) {
    return this.productObject.getTitle(lang)
  }

  getTitleSKU (lang) {
    const title = this.getTitle(lang)
    return titleSKU(title[lang], this.sku)
  }

  getDamagedDescription (lang = 'cs') {
    const langToUse = langMap.get(lang)
    let description = this.damaged_description

    if (langToUse !== 'en') {
      description = this[`damaged_description_${langToUse}`] !== '' ? this[`damaged_description_${langToUse}`] : description
    }

    return description
  }

  getAttributeLoc (id, attributes) {
    const attr = findObject(attributes, item => {
      return item.paramId === id
    })
    let value = getLoc(null, null, null)

    if (attr) {
      value = attr.mainValueLoc
    }

    return value
  }
}

export default Article

export function generateBubbleType (isDiscounted, isPresale, isPromo, isNew) {
  if (isPresale) {
    return 'presale'
  } else if (isPromo) {
    return 'sale-promo'
  } else if (isDiscounted && !isPromo) {
    return 'sale'
  } else if (isNew) {
    return 'new'
  }

  return ''
}

export function generateBubbleContent (discounted, regularPrice, effectivePrice, isPresale, releaseDate, isPromo, isNew) {
  if (isPresale) {
    return releaseDate
  } else if (discounted || isPromo) {
    const percentage = Math.max(1, Math.abs(Math.floor((regularPrice - effectivePrice) / (regularPrice / 100))))
    return `-${percentage}%`
  } else if (isNew) {
    return 'New'
  }

  return ''
}

/**
 *
 * @param {'sale'|'sale-promo'|'presale'|'new'} type
 */
export function getBubbleTypeLocalized (type) {
  const map = {
    sale: 'Akce',
    'sale-promo': 'Akce měsíce',
    presale: 'Předprodej',
    new: 'Novinka',
  }

  return map[type]
}
