import CMSArticle from '@/database/models/CMSArticle'

class CMSNewsArticle extends CMSArticle {
  static entity = 'cmsnewsarticles'
  static primaryKey = 'id'

  static additionalProperties = [
    'perex'
  ]
}

export default CMSNewsArticle
