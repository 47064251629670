import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import filter from 'lodash/filter'
import map from 'lodash/map'
import isNull from 'lodash/isNull'

import { findObject } from '@/services/Helpers'
import NavigationStatic from '@/structures/NavigationStatic'
import AutoCodableN from '~/database/models/AutoCodableN'

export default class NavigationPage extends AutoCodableN {
  static entity = 'navigation_pages'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      params: this.attr({}),
      localized_name: this.attr(null),
      name: this.attr(null),
      name_cz: this.attr(null),
      name_de: this.attr(null),

      description: this.attr(null),
      description_cz: this.attr(null),
      description_de: this.attr(null),

      meta_description: this.attr(null),
      meta_description_cz: this.attr(null),
      meta_description_de: this.attr(null),

      include_nonspecific: this.attr(false),
      inverse_tags: this.attr(false),
      max_slider_czk: this.attr(0),
      max_slider_eur: this.attr(0),

      show_best_seller: this.attr(false),
      contains_products: this.attr(null),
      page_type: this.attr(null),
      category: this.attr(null),
      game: this.attr(null),
      expansion: this.attr(null),
      tags: this.attr(null),
      extra_query_params: this.attr({}),
      image_url: this.attr(null),
      banner_url: this.attr(null),
      icon: this.attr(null),
      special_positioning: this.attr(null),
      url_fragment: this.attr(null),
      url_fragment_cz: this.attr(null),
      url_fragment_de: this.attr(null),
      full_url: this.attr(null),
      full_url_cz: this.attr(null),
      full_url_de: this.attr(null),
      is_special: this.attr(false),
      is_route_name: this.boolean(false),
      children_ids: this.attr([]),
      children: this.attr([]),
    }
  }

  static propertyMap = new Map([
    ['url', 'full_url'],
    ['urlCz', 'full_url_cz'],
    ['urlDe', 'full_url_de'],
    ['urlFragment', 'url_fragment'],
    ['urlFragmentCz', 'url_fragment_cz'],
    ['urlFragmentDe', 'url_fragment_de'],
    ['maxSliderCzk', 'max_slider_czk'],
    ['maxSliderEur', 'max_slider_eur'],
    ['image', 'image_url'],
    ['banner', 'banner_url'],
    ['isSpecial', 'is_special'],
    ['hasProducts', 'contains_products'],
    ['pageType', 'page_type'],
    ['isRouteName', 'is_route_name'],
    ['childrenIds', 'children_ids'],
    ['inverseTags', 'inverse_tags'],
    ['includeNonspecific', 'include_nonspecific'],
    ['localizedName', 'localized_name'],
    ['showBestSeller', 'show_best_seller']
  ])

  get to() {
    let value

    if(this.isRouteName) {
      value = {
        en: {
          name: [this.url, 'en'].join('__'),
          params: this.params
        },
        cs: {
          name: [this.url, 'cs'].join('__'),
          params: this.params
        },
      }
    } else {
      value = this.fullUrlLoc
    }
    return value
  }

  get localizedFragments () {
    return [this.urlFragment, this.urlFragmentCz, this.urlFragmentDe]
  }

  get hasBanner () {
    return !isNull(this.banner)
  }

  get childrenPages () {
    return map(this.children, page => new NavigationPage(page))
  }

  get metaDescriptionLoc() {
    return this.getLoc('meta_description')
  }

  get descriptionLoc() {
    return this.getLoc('description')
  }

  get titleLoc() {
    return this.getLoc('name')
  }

  get urlFragmentLoc() {
    return this.getLoc('url_fragment')
  }

  get isPresale () {
    return this.url === '/presale'
  }

  get isNew () {
    return this.url === '/new'
  }

  get isMtg () {
    return this.urlFragment === 'mtg'
  }

  get isGamingClubGroup () {
    return this.url.includes('gamingClub')
  }

  get isMtgGroup () {
    return this.url.includes('mtg')
  }

  get isCategoryOverview () {
    return !this.hasProducts && this.hasChildren
  }

  get categoryId () {
    let id = this.category

    if (this.category && isObject(this.category) && 'id' in this.category) {
      id = this.category.id
    }

    return id
  }

  get hasChildren () {
    return !isEmpty(this.children)
  }

  get extraQueryParams () {
    return this.extra_query_params || {}
  }

  get fullUrlLoc() {
    return this.getLoc('full_url')
  }

  static isCategoryOverview (pageDataObject) {
    return NavigationStatic.isCategoryOverview(pageDataObject)
  }

  static getCategoryId (pageDataObject) {
    return NavigationStatic.getCategoryId(pageDataObject)
  }

  /**
   *
   * @param {NavigationPage} parent
   * @param {[string]} pathFragments
   * @returns {[]}
   */
  static getRouteArray (parent, pathFragments) {
    const breadcrumbs = [parent]
    pathFragments.shift()

    if (pathFragments.length > 0) {
      let activeRoute = parent

      for (let i = 0; i < pathFragments.length; i++) {
        const navigationPage = findObject(activeRoute.childrenPages, page => {
          return page.localizedFragments.includes(pathFragments[i])
        })

        if (navigationPage) {
          breadcrumbs.push(navigationPage)
          activeRoute = navigationPage
        }
      }
    }

    return breadcrumbs
  }

  static pageTypes = {
    regular: 'REGULAR',
    mtgSingles: 'MTG_SINGLES',
    singlesSell: 'SINGLES_SELL',
    mtgBuylist: 'MTG_BUYLIST',
    singlesBuylist: 'SINGLES_BUYLIST',
    blog: 'BLOG'
  }

  static specialPages = {
    new: 'new',
    discounted: 'discounted',
    presale: 'presale'
  }

  static specialPagesArray = [this.specialPages.discounted, this.specialPages.new, this.specialPages.presale]

  static staticNavigations = {
    shopping: {
      localized_name: 'section_how-to-shop',
      children: [
        new NavigationPage({ full_url: 'shoppingShippingPage', is_route_name: true, localized_name: 'page_howto-shipping', name: 'page_howto-shipping', name_cz: 'page_howto-shipping', name_de: 'page_howto-shipping' }),
        new NavigationPage({ full_url: 'shoppingPaymentPage', is_route_name: true, localized_name: 'page_howto-payment', name: 'page_howto-payment', name_cz: 'page_howto-payment', name_de: 'page_howto-payment' }),
        new NavigationPage({ full_url: 'termsPage', is_route_name: true, localized_name: 'page_howto-terms', name: 'page_howto-terms', name_cz: 'page_howto-terms', name_de: 'page_howto-terms' }),
        new NavigationPage({
          full_url: 'contestTermsPage',
          is_route_name: true,
          localized_name: 'page_howto-contest-terms',
          name: 'page_howto-contest-terms',
          name_cz: 'page_howto-contest-terms',
          name_de: 'page_howto-contest-terms',
          children: [
            new NavigationPage({
              full_url: 'generalContestTermsPage',
              full_url_cz: 'generalContestTermsPage',
              is_route_name: true,
              localized_name: 'page_howto-general-contest-terms',
              name: 'page_howto-general-contest-terms',
              name_cz: 'page_howto-general-contest-terms',
              name_de: 'page_howto-general-contest-terms',

            }),
            new NavigationPage({
              full_url: 'contestTermsRateWinPage',
              full_url_cz: 'contestTermsRateWinPage',
              is_route_name: true,
              localized_name: 'page_howto-contest-terms-rate-win',
              name: 'page_howto-contest-terms-rate-win',
              name_cz: 'page_howto-contest-terms-rate-win',
              name_de: 'page_howto-contest-terms-rate-win',

            }),
          ],
        }),
        new NavigationPage({ full_url: 'privacyPage', is_route_name: true, localized_name: 'page_howto-privacy', name: 'page_howto-privacy', name_cz: 'page_howto-privacy', name_de: 'page_howto-privacy' }),
        new NavigationPage({ full_url: 'claimsPage', is_route_name: true, localized_name: 'page_howto-claims', name: 'page_howto-claims', name_cz: 'page_howto-claims', name_de: 'page_howto-claims' }),
        new NavigationPage({ full_url: 'registrationBenefitsPage', is_route_name: true, localized_name: 'page_howto-registration-benefits', name: 'page_howto-registration-benefits', name_cz: 'page_howto-registration-benefits', name_de: 'page_howto-registration-benefits' }),
        new NavigationPage({ full_url: 'shoppingLoyaltyPage', is_route_name: true, localized_name: 'page_howto-loyalty', name: 'page_howto-loyalty', name_cz: 'page_howto-loyalty', name_de: 'page_howto-loyalty' }),
        new NavigationPage({ full_url: 'gradingTutorialPage', is_route_name: true, localized_name: 'page_howto-grading-tutorial', name: 'page_howto-grading-tutorial', name_cz: 'page_howto-grading-tutorial', name_de: 'page_howto-grading-tutorial' }),
        new NavigationPage({ full_url: 'shoppingRewardPage', is_route_name: true, localized_name: 'page_howto-reward', name: 'page_howto-reward', name_cz: 'page_howto-reward', name_de: 'page_howto-reward' }),
        new NavigationPage({ full_url: 'shoppingBuylistPage', is_route_name: true, localized_name: 'page_howto-buylist', name: 'page_howto-buylist', name_cz: 'page_howto-buylist', name_de: 'page_howto-buylist' }),
        new NavigationPage({ full_url: 'shoppingWantlistPage', is_route_name: true, localized_name: 'page_howto-wantlist', name: 'page_howto-wantlist', name_cz: 'page_howto-wantlist', name_de: 'page_howto-wantlist' }),
      ]
    },
    userArea: {
      children: [
        new NavigationPage({ full_url: 'userOrders', is_route_name: true, localized_name: 'page_user_orders', name: 'page_user_orders', name_cz: 'page_user_orders', name_de: 'page_user_orders' }),
        new NavigationPage({ full_url: 'userBuyup', is_route_name: true, localized_name: 'page_user_buyups', name: 'page_user_buyups', name_cz: 'page_user_buyups', name_de: 'page_user_buyups' }),
        new NavigationPage({ full_url: 'userShoppingLists', is_route_name: true, localized_name: 'page_user_shoppingLists', name: 'page_user_shoppingLists', name_cz: 'page_user_shoppingLists', name_de: 'page_user_shoppingLists' }),
        new NavigationPage({ full_url: 'userCredit', is_route_name: true, localized_name: 'page_user_credits', name: 'page_user_credits', name_cz: 'page_user_credits', name_de: 'page_user_credits' }),
        new NavigationPage({ full_url: 'userTournaments', is_route_name: true, localized_name: 'page_user_tournaments', name: 'page_user_tournaments', name_cz: 'page_user_tournaments', name_de: 'page_user_tournaments' }),
        new NavigationPage({ full_url: 'userAddresses', is_route_name: true, localized_name: 'page_user_addresses', name: 'page_user_addresses', name_cz: 'page_user_addresses', name_de: 'page_user_addresses' }),
        new NavigationPage({ full_url: 'userSettings', is_route_name: true, localized_name: 'page_user_settings', name: 'page_user_settings', name_cz: 'page_user_settings', name_de: 'page_user_settings' }),
        new NavigationPage({ full_url: 'userWantlist', is_route_name: true, localized_name: 'page_user_wantlist', name: 'page_user_wantlist', name_cz: 'page_user_wantlist', name_de: 'page_user_wantlist' })
      ]
    }
  }

  static userCreditVoucherPage = new NavigationPage({ full_url: 'userVoucher', is_route_name: true, localized_name: 'page_user_voucher', name: 'page_user_voucher', name_cz: 'page_user_voucher', name_de: 'page_user_voucher' })

  static getUserNavigation (ignored = []) {
    return filter(this.staticNavigations.userArea.children, page => !ignored.includes(page.url))
  }

  static getUserNavigationMobile (ignored = []) {
    return map([...NavigationPage.getUserNavigation(ignored), this.userCreditVoucherPage], page => {
      if (page.full_url === 'userSettings') {
        const intPage = new NavigationPage(page)
        intPage.localized_name = 'page_user_settings_mobile'
        intPage.name = 'page_user_settings_mobile'
        intPage.name_cz = 'page_user_settings_mobile'
        intPage.name_de = 'page_user_settings_mobile'

        return intPage
      }

      return page
    })
  }
}
