import AutoCodableN from '~/database/models/AutoCodableN'

export default class HomepageTitle extends AutoCodableN {
  static entity = 'homepagetitles'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      name: this.attr(null),
      name_cz: this.attr(null),
      name_de: this.attr(null),

      internal_name: this.attr(null),
      url_fragment: this.attr(null),
      image: this.attr(null)
    }
  }

  static propertyMap = new Map([
    ['nameCz', 'name_cz'],
    ['nameDe', 'name_de'],
    ['internalName', 'internalName'],
    ['urlFragment', 'url_fragment']
  ])

  get titleLoc () {
    return this.getLoc('name')
  }
}
