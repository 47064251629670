import AutoCodableN from '~/database/models/AutoCodableN'
import GCTeam from '~/database/models/GCTeam'

export default class GCMatch extends AutoCodableN {

  constructor(props) {
    super(props)

    this.team1 = new GCTeam(this.team_1 || {})
    this.team2 = new GCTeam(this.team_2 || {})
  }

  static fields() {
    return {
      id: this.attr(1),
      round: this.attr(null),
      team_1: this.attr(null),
      team_2: this.attr(null),
      team_1_score: this.attr(null),
      team_2_score: this.attr(null)
    }
  }

  get isTeam1Winner() {
    return this.team1Score > this.team2Score
  }

  get isTeam2Winner() {
    return this.team2Score > this.team1Score
  }

  get isDraw() {
    return this.team2Score === this.team1Score
  }

  get team1Score() {
    return this.team_1_score
  }

  get team1Name() {
    return this.team1.fullNames
  }

  get team1Id() {
    return this.team1.id
  }

  get team1Players() {
    return this.team1.players
  }

  get team2Score() {
    return this.team_2_score
  }

  get team2Name() {
    return this.team2.fullNames
  }

  get team2Id() {
    return this.team2.id
  }

  get team2Players() {
    return this.team2.players
  }
}
