import AutoCodableN from '~/database/models/AutoCodableN'

export default class CMSTitle extends AutoCodableN {
  static fields () {
    return {
      value: this.attr(null),
      value_cz: this.attr(null),
      article_count: this.attr(0),
      color: this.attr(null),
      icon_code: this.attr(null)
    }
  }

  static propertyMap = new Map([
    ['count', 'article_count']
  ])

  get id () {
    return this.value
  }

  get label () {
    return this.value
  }

  get labelLoc () {
    return this.getLocWithDefault('value')
  }

  get icon () {
    let value = null

    if (this.icon_code) {
      value = `cms-title-${this.icon_code}`
    }

    return value
  }
}
