import AutoCodableN from '~/database/models/AutoCodableN'

export default class MagicArtist extends AutoCodableN {
  static entity = 'magic_artists'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      label: this.attr(null),
      icon: this.attr(null),
      title: this.attr(null)
    }
  }
}
