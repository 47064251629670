import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import { parsePhoneNumberFromString } from '~/structures/filters/PhoneValidator'

import LogService from '@/services/LogService'

import Codable from '@/database/models/Codable'

class Address extends Codable {
  static entity = 'addresses'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      user: this.attr(null),
      first_name: this.attr(''),
      last_name: this.attr(''),
      street: this.attr(null),
      street2: this.attr(''),
      zip_code: this.attr(null),
      province: this.attr(''),
      city: this.attr(null),
      country: this.attr('CZ'),
      phone: this.attr(''),
      phoneObject: this.attr({code: '+420', number: ''}),
      company_name: this.attr(''),
      company_id: this.attr(''),
      company_tax_id: this.attr('')
    }
  }

  static mapProperties = function (object, parameters = {}) {
    const result = Codable.mapProperties(object, parameters)
    delete result.phoneObject
    return result
  }

  static propertyMap = new Map([
    ['firstname', 'first_name'],
    ['lastname', 'last_name'],
    ['name', 'address_name'],
    ['zip', 'zip_code'],
    ['companyName', 'company_name'],
    ['companyId', 'company_id'],
    ['companyTaxId', 'company_tax_id']
  ])

  get parsedPhone () {
    let parsedPhoneNumber
    try {
      parsedPhoneNumber = parsePhoneNumberFromString(this.phone)
    } catch (e) {
      LogService.error(e)
      LogService.warn(this.phone)
      return {
        code: '',
        number: ''
      }
    }

    if (parsedPhoneNumber) {
      return {
        code: `+${parsedPhoneNumber.countryCallingCode}`,
        number: parsedPhoneNumber.nationalNumber
      }
    } else {
      return {
        ...this.phoneObject
      }
    }
  }

  set parsedPhone (value) {
    this.phoneObject = {...value}
    this.phone = `${value.code}${value.number}`
  }

  get formattedPhoneNumber () {
    const phoneNumber = this.phone
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber)

    if (parsedPhoneNumber) {
      return parsedPhoneNumber.formatInternational()
    }

    return phoneNumber
  }

  get hasPhoneNumber () {
    return (!isNull(this.phone) && !isEmpty(this.phone))
  }

  get firstname () {
    return this.first_name
  }

  set firstname (value) {
    this.first_name = value
  }

  get lastname () {
    return this.last_name
  }

  set lastname (value) {
    this.last_name = value
  }

  get zip () {
    return this.zip_code
  }

  set zip (value) {
    this.zip_code = value
  }

  get companyName () {
    return this.company_name
  }

  set companyName (value) {
    this.company_name = value
  }

  get companyId () {
    return this.company_id
  }

  set companyId (value) {
    this.company_id = value
  }

  get companyTaxId () {
    return this.company_tax_id
  }

  set companyTaxId (value) {
    this.company_tax_id = value
  }

  get isValid () {
    return this.hasNames && !isEmpty(this.street) && !isEmpty(this.city) && !isEmpty(this.zip)
  }

  get isValidForStorePickup () {
    return this.hasNames && this.hasPhone
  }

  get hasNames () {
    return !isEmpty(this.firstname) && !isEmpty(this.lastname)
  }

  get hasPhone () {
    return !isEmpty(this.phone)
  }
}

export default Address
