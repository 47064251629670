import isNull from 'lodash/isNull'

import UserLoyaltyLevel from '@/database/models/UserLoyaltyLevel'
import AutoCodableN from '~/database/models/AutoCodableN'

/**
 * Model representing user's basic data
 */
class User extends AutoCodableN {
  static entity = 'users'
  static primaryKey = 'email'

  static fields () {
    return {
      id: this.attr(1),
      is_active: this.attr(true),
      is_staff: this.attr(false),
      first_name: this.attr(''),
      last_name: this.attr(''),
      picture: this.attr(null),
      has_facebook: this.attr(false),
      dci_number: this.attr(null),
      email: this.attr(''),
      username: this.attr(''),
      phone: this.attr('+420111111111'),
      currency: this.attr('CZK'),
      country: this.attr('CZ'),
      current_credit_status: this.number(0),
      leaguePoints: this.attr(212),
      leaguePosition: this.attr(1),
      loyalty: this.attr({}),
      game_interests: this.attr([]),
      cms_title_subscription: this.attr([]),
      agree_to_terms_and_conditions: this.attr(false),
      agree_to_marketing_emails: this.attr(true),
      agree_to_order_update_emails: this.attr(true),
      agree_to_cookies: this.attr(false),
      agree_to_order_sms: this.attr(false),
      agree_to_cms_notification: this.attr(false),
      primary_shipping_address: this.attr(null),
      primary_billing_address: this.attr(null),
    }
  }

  static propertyMap = new Map([
    ['firstname', 'first_name'],
    ['lastname', 'last_name'],
    ['hasFacebook', 'has_facebook'],
    ['dciNumber', 'dci_number'],
    ['isActive', 'is_active'],
    ['gameInterests', 'game_interests'],
    ['cmsTitleSubscription', 'cms_title_subscription'],
    ['currentCreditStatus', 'current_credit_status'],
    ['agreeToTermsAndConditions', 'agree_to_terms_and_conditions'],
    ['agreeToMarketingEmails', 'agree_to_marketing_emails'],
    ['agreeToOrderUpdateEmails', 'agree_to_order_update_emails'],
    ['agreeToCookies', 'agree_to_cookies'],
    ['agreeToOrderSms', 'agree_to_order_sms'],
    ['agreeToCmsNotification', 'agree_to_cms_notification'],
    ['primaryShippingAddressId', 'primary_shipping_address'],
    ['primaryBillingAddressId', 'primary_billing_address'],

    ['currentPassword', 'current_password'],
    ['newPassword', 'new_password'],
    ['newEmail', 'new_email']
  ])

  static LoyaltyLevels = UserLoyaltyLevel

  static Error = {
    anonymousCheckoutEmailRegistered: 'anonymous_checkout_email_registered',
    invalidData: 'invalid',
    invalidZip: 'zip_code_invalid',
    eventLinkNotFound: 'eventlink_not_found'
  }

  get hasUnfinishedRegistration () {
    return this.agreeToTermsAndConditions === false
  }

  get isStaff () {
    return this.is_staff
  }

  get fullName () {
    return `${this.first_name} ${this.last_name}`
  }

  get hasCzechPhone () {
    return this.phone && this.phone.startsWith('+420')
  }

  get credit () {
    return this.current_credit_status
  }

  get loyaltyLevel () {
    return isNull(this.loyalty.current_level) ? null : this.loyalty.current_level
  }

  get loyaltyDiscountPercentage () {
    return isNull(this.loyalty.percent_discount) ? null : this.loyalty.percent_discount
  }

  get loyaltyNextLevel () {
    return isNull(this.loyalty.next_level) ? null : this.loyalty.next_level
  }

  get loyaltyProgress () {
    return isNull(this.loyalty.progress_to_next_level) ? 0 : Math.floor(this.loyalty.progress_to_next_level * 100)
  }
}

export default User
