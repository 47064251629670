import AutoCodableN from '~/database/models/AutoCodableN'

export default class Hello extends AutoCodableN {
  static fields () {
    return {
      country: this.attr('CZ'),
      currency: this.attr('CZK'),
      language: this.attr('cs'),
      artists: this.number(0),
      expansions: this.number(0),
      games: this.number(0),
      subtypes: this.number(0)
    }
  }

  get timestamps () {
    return {
      artists: this.artists,
      expansions: this.expansions,
      games: this.games,
      subtypes: this.subtypes
    }
  }
}
