import isObject from 'lodash/isObject'
import map from 'lodash/map'
import AutoCodableN from '~/database/models/AutoCodableN'

export default class ProductTag extends AutoCodableN {
  static fields () {
    return {
      id: this.attr(1),
      image_url: this.attr(null),
      internal_name: this.attr(null),
      localized_name: this.attr(null),
      name: this.attr(null),
      name_cz: this.attr(null),
      name_de: this.attr(null),
      tag_type: this.attr(null)
    }
  }

  static propertyMap = new Map([
    ['internalName', 'internal_name']
  ])

  /**
   * @typedef {String} ProductTag.BaseType
   * @enum {ProductTag.BaseType}
   */
  static BaseType = {
    round: 'round',
    oval: 'oval',
    square: 'square',
    triangle: 'triangle',
    transparent: 'transparent',
    none: 'base_none'
  }

  get titleLoc () {
    return this.getLocWithDefault('name', this.internalName)
  }

  get icon () {
    return this.image_url
  }

  get tagType () {
    return this.tag_type
  }

  get tagTypeName () {
    let value = null

    if (isObject(this.tagType) && 'internal_name' in this.tagType) {
      value = this.tagType.internal_name
    }

    return value
  }

  get baseType () {
    const bases = map(ProductTag.BaseType)
    let base = null

    for (let i = 0; i < bases.length; i++) {
      const type = bases[i]

      if (this.internalName.includes(type)) {
        base = type
        break
      }
    }

    return base
  }

  get isMiniatureBase () {
    return this.baseType !== null
  }
}
