import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import each from 'lodash/each'
import isNull from 'lodash/isNull'

import ProductCondition from '@/structures/ProductCondition'
import ProductRepresentable from '~/database/ProductRepresentable'
import Product from '@/database/models/Product'
import { getLoc } from '~/structures/Localizable'

class BuyupOffer extends ProductRepresentable {
  static entity = 'buyupoffers'
  static primaryKey = 'computedId'

  static fields () {
    return {
      id: this.attr(1),
      product: this.attr(null),
      condition: this.attr('NM'),
      count: this.attr(0),
      is_foil: this.boolean(false),
      prices: this.attr({}),
      featured: this.boolean(false),
      available_for_buylist: this.number(0)
    }
  }

  get hasPrices() {
    return !isEmpty(this.prices)
  }

  get computedId () {
    return `${this.id}-${this.condition}`
  }

  get isNonSingle () {
    return this.productObject.isNonSingle
  }

  get title () {
    return this.productObject.title
  }

  get titleLoc () {
    return this.productObject.titleLoc
  }

  get descriptionPartsLoc () {
    const items = []
    const expansionTitleLoc = this.expansionTitleLoc
    if (this.isFoil) items.push('Foil')
    if (this.condition) items.push(this.condition)

    const descriptionLoc = {
      en: [...items],
      cs: [...items],
      de: [...items],
    }
    each(Object.keys(expansionTitleLoc), lang => {
      if(!isNull(expansionTitleLoc[lang]) && !isEmpty(expansionTitleLoc[lang])) {
        descriptionLoc[lang].unshift(expansionTitleLoc[lang])
      }
    })

    return getLoc(descriptionLoc.en.join(', '), descriptionLoc.cs.join(', '), descriptionLoc.de.join(', '))
  }

  get image () {
    return this.productObject.image
  }

  get expansionIcon () {
    return this.productObject.expansionIcon
  }

  get expansionTitleLoc () {
    return this.productObject.expansionTitleLoc
  }

  get expansionShortCode () {
    return this.productObject.expansionShortCode
  }

  get hasMoreLanguages () {
    return this.expansionBuylistLanguages.length > 1
  }

  get expansionBuylistLanguages () {
    return this.productObject.expansionBuylistLanguages
  }

  get languageCodes () {
    const langs = map(this.expansionBuylistLanguages, 'code')
    const priceLangs = this.getPriceLanguages()

    return filter(langs, lang => {
      return priceLangs.includes(lang.toLowerCase())
    })
  }

  get expansionId () {
    return this.productObject.expansionObject.id
  }

  get hasRarity () {
    return this.productObject.hasRarity
  }

  get rarity () {
    return this.productObject.rarity
  }

  get manaCost () {
    return this.productObject.manaCost
  }

  get isOversized () {
    return this.productObject.isOversized
  }

  get isFoil () {
    return this.is_foil
  }

  get availableForBuylist () {
    return this.available_for_buylist
  }

  get availableConditions () {
    const conditions = cloneDeep(ProductCondition.options)
    const priceKeys = this.priceKeys

    if (this.isFoil) {
      // Najada doesn't buy Played and Heavily played foil cards
      delete conditions.PL
      delete conditions.HP
    }

    // Show only conditions available for buylist based on prices config
    for (const priceKey in conditions) {
      if(!priceKeys.includes(priceKey)) {
        delete conditions[priceKey]
      }
    }

    return conditions
  }

  get isNearMintOnly () {
    return isEqual(this.priceKeys, ['NM'])
  }

  get availableConditionsShort () {
    return mapValues(this.availableConditions, (value, key) => key)
  }

  get isPurchasable () {
    return this.availableForBuylist > 0
  }

  get numberOfItemsAvailable () {
    return this.available_for_buylist || 0
  }

  set numberOfItemsAvailable (value) {
    this.available_for_buylist = value
  }

  get hasProduct () {
    return this.product !== null
  }

  get productObject () {
    return new Product(this.product)
  }

  get baseUnitPrice () {
    return this.getPrice()
  }

  get priceKeys() {
    const currency = 'CZK'
    let keys = []
    let lang = 'en'

    if(!this.languageCodes.includes(lang) && !isEmpty(this.languageCodes)) {
      lang = this.languageCodes[0].toLowerCase()
    }

    if(currency in this.prices && lang in this.prices[currency]) {
      keys = Object.keys(this.prices[currency][lang])
    }

    return keys
  }

  getPriceLanguages () {
    const prices = { ...this.prices }
    const currencyKeys = Object.keys(prices)

    if (!isEmpty(currencyKeys)) {
      const langsObject = prices[currencyKeys[0]]
      const langKeys = Object.keys(prices[currencyKeys[0]])

      return filter(langKeys, lang => {
        return !isEmpty(langsObject[lang])
      })
    }

    return []
  }


  getPrice (currency = 'CZK', condition = 'NM', language = 'en') {
    const langLower = language.toLowerCase()

    if (currency in this.prices && langLower in this.prices[currency] && condition in this.prices[currency][langLower]) {
      return this.prices[currency][langLower][condition]
    }

    return 0
  }

  getOptions (currency = 'CZK', language = 'en') {
    let options = {}
    const langLower = language.toLowerCase()

    if (currency in this.prices && langLower in this.prices[currency]) {
      options = { ...this.prices[currency][langLower] }
    }

    if (this.isFoil) {
      // Najada doesnt buy Played and Heavily played foil cards
      delete options.PL
      delete options.HP
    }

    return options
  }
}

export default BuyupOffer

export function getPriceOptions(prices = {}, currency = 'CZK', language = 'en', isFoil = false) {
  let options = {}
  const langLower = language.toLowerCase()

  if (currency in prices && langLower in prices[currency]) {
    options = { ...prices[currency][langLower] }
  }

  if (isFoil) {
    // Najada doesnt buy Played and Heavily played foil cards
    delete options.PL
    delete options.HP
  }

  return options
}
