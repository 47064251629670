import range from 'lodash/range'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import AutoCodableN from '~/database/models/AutoCodableN'
import GCGame from '~/database/models/GCGame'
import User from '~/database/models/User'
import GCStanding from '~/database/models/GCStanding'

export default class GCPlayerProfile extends AutoCodableN {
  constructor(props) {
    super(props);

    this.gameObject = new GCGame(this.game || {})
    this.userObject = new User(this.user || {})
    this.standingsObjects = map(this.standings || [], standing => new GCStanding(standing))
  }

  static fields () {
    return {
      id: this.attr(1),
      player_id: this.attr(null),
      game: this.attr(null),
      user: this.attr(null),
      email: this.attr(null),
      nickname: this.attr(null),
      first_name: this.attr(null),
      last_name: this.attr(null),
      avatar: this.attr('avatar-1'),
      league: this.attr(null),
      is_public_stats: this.attr(null),
      achievement_count: this.attr(0),
      standings: this.attr([])
    }
  }

  get hasStandings() {
    return !isEmpty(this.standings)
  }

  get hasAnyAchievements() {
    return this.achievement_count !== null && this.achievement_count > 0
  }

  get isPublicStats() {
    return this.is_public_stats
  }

  get fullName() {
    return `${this.firstname} ${this.lastname}`
  }

  get firstname() {
    return this.first_name
  }

  get lastname() {
    return this.last_name
  }

  get shortenedName() {
    const names = [this.lastname]
    const firstname = this.firstname

    if(!isEmpty(firstname)) {
      names.unshift(firstname[0])
    }

    return names.join('. ')
  }
}

/**
 *
 * @return {string[]}
 */
export function generateAvatars(game) {
  const gameName = game.toLowerCase()
  const start = 1
  let end = 9

  if(AVATARS_EXTENDED.includes(gameName)) {
    end = 17
  }

  const numberRange = range(start, end)
  return map(numberRange, number => `avatar-${number}`)
}

export const AVATARS_EXTENDED = ['mtg', 'pokemon', 'flesh&blood']

export const AVATARS_AVAILABLE = [
  'digimon',
  'flesh&blood',
  'general',
  'lorcana',
  'mtg',
  'onepiece',
  'pokemon',
  'star wars: unlimited',
  'vanguard',
  'yugioh',
]

export const AVATAR_FOLDER_MAP = new Map([
  ['star wars: unlimited', 'starwars-unlimited']
])
