import AutoCodableN from '~/database/models/AutoCodableN'

export default class WZGame extends AutoCodableN {
  static entity = 'wzgames'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      localized_name: this.attr(null),
      name: this.attr(null),
      name_cz: this.attr(null),
      name_de: this.attr(null),

      game_category: this.attr(null),
      image_url: this.attr(null),
      customer_interest_type: this.attr(null),
      type: this.attr(null)
    }
  }

  static propertyMap = new Map([
    ['gameCategory', 'game_category'],
    ['customerInterestType', 'customer_interest_type'],
    ['icon', 'image_url']
  ])

  /**
   * @typedef {String} WZGame.Category
   * @type {WZGame.Category}
   */
  static Category = {
    tradingCardGame: 'Trading Card Game',
    livingCardGame: 'Living Card Game',
    rgp: 'RPG',
    miniature: 'Minitature',
    tabletop: 'Tabletop',
    comic: 'Comic',
    nonspecific: 'Nonspecific'
  }

  get labelLoc() {
    return this.getLocWithDefault('name', this.name)
  }
}
