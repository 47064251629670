import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import AutoCodableN from '~/database/models/AutoCodableN'
import GCGame from '~/database/models/GCGame';
import GCMatch from '~/database/models/GCMatch'
import GCStanding from '~/database/models/GCStanding'
import {dayMonthYearHuman} from '~/services/DateService';
import {getNumberFromTime} from '~/services/Helpers'
import {getLoc, getLocCurrency} from '~/structures/Localizable'

export default class GCTournament extends AutoCodableN {
  constructor(props) {
    super(props);

    this.gameObject = new GCGame(this.game || {})
    this.dateFormatted = dayMonthYearHuman(this.date)
    this.bannerLoc = getLoc(this.banner_en, this.banner_cz)
    this.bannerSmallLoc = getLoc(this.banner_small_en, this.banner_small_cz)
    this.nameLoc = getLoc(this.name_en, this.name_cz)
    this.descriptionLoc = getLoc(this.description_en, this.description_cz)
    this.prizesLoc = getLoc(this.prizes_en, this.prizes_cz)
    this.entryCreditFeeLoc = getLocCurrency(this.entry_credit_fee_czk, this.entry_credit_fee_eur)
    this.entryDescriptionLoc = getLoc(this.entry_description_en, this.entry_description_cz)
    this.start = this.processTime(this.start || '')
    this.registration = this.processTime(this.registration || '')
    this.matchesObjects = sortBy(map(this.matches, match => new GCMatch(match)), 'round')
    this.myStandingObject = new GCStanding(isEmpty(this.my_standing || []) ? {} : this.my_standing[0])
    this.standingsObjects = sortBy(map(this.standings, item => new GCStanding(item)), 'rank')
  }

  static fields () {
    return {
      id: this.attr(1),
      banner_en: this.attr(null),
      banner_cz: this.attr(null),
      banner_small_en: this.attr(null),
      banner_small_cz: this.attr(null),
      name_cz: this.attr(null),
      name_en: this.attr(null),
      description_cz: this.attr(null),
      description_en: this.attr(null),
      prizes_cz: this.attr(null),
      prizes_en: this.attr(null),
      game: this.attr(null),
      format: this.attr(null),
      style: this.attr(null),
      rule: this.attr(null),
      capacity: this.attr(null),
      number_of_rounds: this.attr(null),
      team_size: this.attr(null),
      league_multiplier: this.attr(null),
      date: this.attr(null),
      start: this.attr(null),
      registration: this.attr(null),
      status: this.attr(null),
      external_id: this.attr(null),
      external_short_code: this.attr(null),
      credit_prize: this.attr(null),
      link: this.attr(null),
      repeated: this.attr(null),
      featured: this.attr(false),
      sign_in: this.attr(false),
      decklist: this.attr(null),
      own_decklist: this.attr(null),
      published: this.attr(null),
      has_top_8: this.attr(null),
      is_enrolled: this.attr(false),
      player_count: this.attr(0),
      credit_has_been_split_up: this.attr(false),
      entry_credit_fee_czk: this.attr(0),
      entry_credit_fee_eur: this.attr(0),
      entry_description_cz: this.attr(null),
      entry_description_en: this.attr(null),
      matches: this.attr([]),
      my_standing: this.attr(null),
      standings: this.attr([])
    }
  }

  static eligibleStatus() {
    return ['SCHEDULED', 'STARTED'].join(',')
  }

  get top3() {
    return this.standingsObjects.slice(0, 3)
  }

  get numberOfRounds() {
    return this.number_of_rounds
  }

  get hasOwnDecklist() {
    return this.own_decklist !== null
  }

  get externalShortCode() {
    return this.external_short_code
  }

  get hasSignIn() {
    return this.sign_in
  }

  get startTimeNumber() {
    return getNumberFromTime(this.start)
  }

  get hasMyStanding() {
    return this.my_standing !== null
  }

  get playerCount() {
    return this.player_count
  }

  get isEnrolled() {
    return this.is_enrolled
  }

  get isMultiplier2() {
    return this.league_multiplier === 2
  }

  get isMultiplier3() {
    return this.league_multiplier === 3
  }

  get isRepeated() {
    return [Repeated.week, Repeated.twoWeeks, Repeated.month].includes(this.repeated)
  }

  get creditPrize() {
    return this.credit_prize || 0
  }

  get entryDescription() {
    return this.entry_description
  }

  /**
   *
   * @param {string} timeString
   * @return {string}
   */
  processTime(timeString) {
    const parts = timeString.split(':')

    if (parts.length > 2) {
      parts.pop()
    }

    return parts.join(':')
  }

  getMyMatches(id) {
    let myMatches = filter(this.matchesObjects, match => {
      return match.team1Players.some(item => item.id === id) || match.team2Players.some(item => item.id === id)
    })

    myMatches = map(myMatches, match => {
      const shouldSwitch = match.team2Players.some(item => item.id === id)
      let matchCopy = match

      if(shouldSwitch) {
        matchCopy = new GCMatch({
          ...match,
          team_1: match.team_2,
          team_1_score: match.team_2_score,
          team_2: match.team_1,
          team_2_score: match.team_1_score
        })
      }

      return matchCopy
    })

    return myMatches
  }
}

export const Repeated = {
  week: 'WEEK',
  twoWeeks: 'TWO_WEEKS',
  month: 'MONTH',
}
