import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import CMSBannerType from '@/database/models/CMSBannerType'
import AutoCodableN from '~/database/models/AutoCodableN'
import { getLoc } from '~/structures/Localizable'

class CMSBanner extends AutoCodableN {
  static fields () {
    return {
      id: this.attr(1),
      navigation_page: this.attr(null),
      banner_type: this.attr(1),
      slider_priority: this.attr(0),
      link: this.attr(null),
      header: this.attr(null),
      header_cz: this.attr(null),
      header_de: this.attr(null),
      title: this.attr(null),
      title_cz: this.attr(null),
      title_de: this.attr(null),
      subtitle: this.attr(null),
      subtitle_cz: this.attr(null),
      subtitle_de: this.attr(null),
      button: this.attr(null),
      button_cz: this.attr(null),
      button_de: this.attr(null),
      button_mobile: this.attr(null),
      button_mobile_cz: this.attr(null),
      button_mobile_de: this.attr(null),
      image_url: this.attr(null),
      image_url_cz: this.attr(null),
      image_url_de: this.attr(null),
      image_url_mobile: this.attr(null),
      image_url_mobile_cz: this.attr(null),
      image_url_mobile_de: this.attr(null),
      allowed_on_desktop: this.boolean(false),
      allowed_on_mobile: this.boolean(false)
    }
  }

  static BannerType = CMSBannerType

  static propertyMap = new Map(
    [
      ['navigationPage', 'navigation_page'],
      ['bannerType', 'banner_type'],
      ['sliderPriority', 'slider_priority'],
      ['allowedOnDesktop', 'allowed_on_desktop'],
      ['allowedOnMobile', 'allowed_on_mobile'],
    ]
  )

  get imageUrlLoc () {
    return this.getLocWithDefault('image_url')
  }

  get imageUrlMobileLoc () {
    return this.getLocWithDefault('image_url_mobile')
  }

  get headerLoc () {
    return this.getLoc('header')
  }

  get titleLoc () {
    return this.getLoc('title')
  }

  get subtitleLoc () {
    return this.getLoc('subtitle')
  }

  get buttonLoc () {
    return this.getLoc('button')
  }

  get buttonMobileLoc () {
    return this.getLoc('button_mobile')
  }

  get hasLink () {
    return !isNull(this.link) && !isEmpty(this.link)
  }

  get isLinkExternal () {
    if (!this.hasLink) return false

    return this.link.includes('http') || this.link.includes('www')
  }

  get hasNavigation () {
    return !isNull(this.navigationPage)
  }

  get fullUrlLoc () {
    return getLoc(
      this.navigationPage.full_url,
      this.navigationPage.full_url_cz,
      this.navigationPage.full_url_de)
  }
}

export default CMSBanner
