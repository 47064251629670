import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import AutoCodableN from '~/database/models/AutoCodableN'
import GCPlayerProfile from '~/database/models/GCPlayerProfile'

export default class GCTeam extends AutoCodableN {
  constructor(props) {
    super(props)

    this.name = (this.name || '').replace('(MTG)', '')
    this.playersObjects = map(this.players, player => new GCPlayerProfile(player))
  }

  static fields() {
    return {
      id: this.attr(1),
      players: this.attr([]),
      name: this.attr(null)
    }
  }

  get fullNames() {
    return map(this.playersObjects, player => player.fullName).join(', ')
  }

  get avatar() {
    let value = 'avatar-1'

    if(!isEmpty(this.playersObjects)) {
      value = this.playersObjects[0].avatar
    }

    return value
  }

  get isPublicStats() {
    let value = false

    if(!isEmpty(this.playersObjects)) {
      value = this.playersObjects[0].isPublicStats
    }

    return value
  }

  get playerId() {
    let value = null

    if(!isEmpty(this.playersObjects)) {
      value = this.playersObjects[0].id
    }

    return value
  }
}
