import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import AutoCodableN from '~/database/models/AutoCodableN'
import ShoppingListItem from '~/database/models/ShoppingListItem'
import { dayMonthYearTime } from '~/services/DateService'
import { getLocCurrency } from '~/structures/Localizable'

export default class ShoppingList extends AutoCodableN {
  constructor(props) {
    super(props)

    this.deletable = true
    this.loading = false
    this.updateModel()
  }

  updateModel() {
    this.createdFormatted = dayMonthYearTime(this.created)
    this.parsedItems = map(this.items, item => new ShoppingListItem(item))
    this.parsedItem = new ShoppingListItem(this.item || {})

    if(this.base_unit_price_czk_sum !== null) {
      this.totalPriceLoc = getLocCurrency(
        this.base_unit_price_czk_sum,
        this.base_unit_price_eur_sum
      )
    } else {
      this.totalPriceLoc = getLocCurrency(
        sumBy(this.parsedItems, listItem => listItem.priceLoc.czk),
        sumBy(this.parsedItems, listItem => listItem.priceLoc.eur)
      )
    }
  }

  static fields() {
    return {
      id: this.attr(1),
      count: this.attr(0),
      items_count: this.attr(null),
      created: this.attr(null),
      modified: this.attr(null),
      name: this.attr(null),
      items: this.attr([]),
      item: this.attr(null),
      user: this.attr(null),
      session_key: this.attr(null),
      anonymous_access_key: this.attr(null),
      is_automatic: this.attr(false),
      visited: this.attr(false),
      existing_shopping_list: this.attr(null),

      base_unit_price_czk_sum: this.attr(null),
      base_unit_price_eur_sum: this.attr(null),
    }
  }

  get accessKey() {
    return this.anonymous_access_key
  }

  get modifiedTimestamp() {
    return +(new Date(this.modified))
  }

  get isAutomatic() {
    return this.is_automatic
  }

  get requiresReminder() {
    return this.isAutomatic && !this.visited
  }

  get itemsCount() {
    return this.items_count !== null ? this.items_count : this.items.length
  }

  get articlesCount() {
    return this.count
  }
}

export const CartTypeChoice = {
  cheapFirst: 'cheap_first',
  bestQuality: 'best_quality'
}
