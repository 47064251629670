import Cart from '@/database/models/Cart'

export default class BuyCart extends Cart {
  static entity = 'buycarts'
  static primaryKey = 'id'

  static fields () {
    return {
      ...Cart.fields(),
      items: this.attr([]),
      count_and_price_updated: this.attr(null)
    }
  }

  get countPriceUpdated() {
    return this.count_and_price_updated
  }
}
