import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import Codable from '@/database/models/Codable'
import Voucher from '~/structures/Voucher'
import { getLocCurrency } from '~/structures/Localizable'

class Cart extends Codable {
  static entity = 'carts'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1), // buy || buyup
      estimated_dispatch_date: this.attr(null),
      estimated_pickup_date: this.attr(null),
      subtotal_czk: this.number(0),
      subtotal_czk_not_rounded: this.number(0),
      subtotal_eur: this.number(0),
      potential_next_loyalty_level: this.attr(null),
      loyalty_discount_total_czk: this.number(0),
      loyalty_discount_total_eur: this.number(0),
      voucher_discount_total_czk: this.number(0),
      voucher_discount_total_eur: this.number(0),
      total_base_price_czk: this.number(0),
      total_base_price_eur: this.number(0),
      applied_voucher: this.attr({}),
      applied_promo_action: this.attr({}),
      items_voucher_applied_to: this.number(0),
      has_multiple_discount_types: this.boolean(false),
      cart_over_weight: this.boolean(false),
      items: this.attr([])
    }
  }

  get estimatedDispatchDate () {
    return this.estimated_dispatch_date
  }

  get estimatedPickupDate () {
    return this.estimated_pickup_date
  }

  get hasMultipleDiscountTypes () {
    return this.has_multiple_discount_types || false
  }

  get numberOfItemsWithVoucherAppliedTo () {
    return this.items_voucher_applied_to || 0
  }

  get hasLoyaltyDiscountApplied () {
    return false
  }

  get potentialNextLoyaltyLevelObject () {
    return this.potential_next_loyalty_level
  }

  get hasVoucherApplied () {
    return (!isNull(this.applied_voucher) && !isEmpty(this.applied_voucher)) || (!isNull(this.applied_promo_action) && !isEmpty(this.applied_promo_action))
  }

  get voucher () {
    return new Voucher(this.applied_voucher || {discount_percentage: null})
  }

  get promoVoucher () {
    return new Voucher(this.applied_promo_action || {discount_percentage: null})
  }

  get totalPriceLoc () {
    return this.getLocCurrency('total_base_price')
  }

  get subtotalCzk () {
    return this.subtotal_czk
  }

  get userTotalPriceLoc () {
    return this.getLocCurrency('subtotal')
  }

  get userTotalPriceNotRoundedLoc () {
    return getLocCurrency(this.subtotal_czk_not_rounded, this.subtotal_eur)
  }

  get loyaltyDiscountLoc() {
    return this.getLocCurrency('loyalty_discount_total')
  }

  get voucherDiscountLoc() {
    return this.getLocCurrency('voucher_discount_total')
  }

  get isVoucherAbsolute() {
    return this.voucher.isAbsoluteDiscount
  }

  get isPromoVoucherAbsolute() {
    return this.promoVoucher.isAbsoluteDiscount
  }

  get isOverWeight () {
    return this.cart_over_weight
  }
}

export default Cart
