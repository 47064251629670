import Codable from '@/database/models/Codable'
import { getLocCurrency } from '~/structures/Localizable'

class ProductRepresentable extends Codable {
  get titleLoc () {
    return null
  }

  get image () {
    return ''
  }

  get combinedMedia () {
    return []
  }

  get baseUnitPriceLoc() {
    return getLocCurrency(0, 0)
  }

  get effectiveUnitPriceLoc() {
    return getLocCurrency(0, 0)
  }
}

export default ProductRepresentable
