import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'
import Article from '~/database/models/Article'
import AutoCodableN from '~/database/models/AutoCodableN'
import Product from '~/database/models/Product'
import { getLocCurrency } from '~/structures/Localizable'

export default class ShoppingListItem extends AutoCodableN {
  static fields() {
    return {
      id: this.attr(1),
      product: this.attr(null),
      articles: this.attr([]),
      condition_min: this.attr('NM'),
      language: this.attr(null),
      is_foil: this.attr(false),
      english_only: this.attr(false),
      count: this.attr(0),
      base_unit_price_czk: this.attr(0),
      base_unit_price_eur: this.attr(0),
    }
  }

  static createForm () {
    return new ShoppingListItem({
      count: 1
    })
  }

  static propertyMap = new Map([
    ['baseUnitPriceCzk', 'base_unit_price_czk'],
    ['baseUnitPriceEur', 'base_unit_price_eur'],
    ['conditionMin', 'condition_min'],
    ['isFoil', 'is_foil'],
  ])

  get baseUnitPriceLoc() {
    return getLocCurrency(this.baseUnitPriceCzk, this.baseUnitPriceEur)
  }

  get hasPriceChanged() {
    let value = false

    if(this.hasArticles) {
      const cheapestPriceLoc = this.cheapestPriceLoc
      value = this.baseUnitPriceCzk !== cheapestPriceLoc.czk
    }

    return value
  }

  get priceChangePositive() {
    let value = false

    if(this.hasArticles) {
      value = this.baseUnitPriceLoc.czk > this.cheapestPriceLoc.czk
    }

    return value
  }

  get selectableCount() {
    const article = this.hasOneArticle ? this.parsedArticles[0] : null
    return Math.min(this.count, article ? article.stockItems : 0)
  }

  get conditionMinFormatted() {
    const conditionMin = this.conditionMin
    const append = conditionMin === 'NM' ? '' : '+'
    return `${conditionMin}${append}`
  }

  get englishOnly() {
    return this.english_only
  }

  set englishOnly(value) {
    this.english_only = value
  }

  get titleLoc() {
    return this.productObject.titleLoc
  }

  get productObject() {
    return new Product(this.product)
  }

  get parsedArticles() {
    return map(this.articles, article => new Article(article))
  }

  get parsedAvailableArticles() {
    return filter(this.parsedArticles, article => article.numberOfItemsAvailable > 0)
  }

  get totalArticleModel() {
    const article = new Article({})
    const parsedArticles = this.parsedArticles
    article.per_user_limit = sumBy(parsedArticles, 'per_user_limit')
    article.total_availability = sumBy(parsedArticles, 'total_availability')
    return article
  }

  get hasArticles() {
    return this.articles.length > 0
  }

  get hasOneArticle() {
    return this.articles.length === 1
  }

  get hasOneAvailableArticle() {
    return this.parsedAvailableArticles.length === 1
  }

  get hasMultipleArticles() {
    return this.articles.length > 1
  }

  get hasMultipleAvailableArticles() {
    return this.parsedAvailableArticles.length > 1
  }

  get hasAnyAvailableArticle() {
    return this.parsedAvailableArticles.length > 0
  }

  get oneArticle() {
    const article = this.parsedArticles[0]
    article.product = {...this.product}
    return article
  }

  get oneAvailableArticle() {
    const availableArticles = this.parsedAvailableArticles
    let value = null

    if(!isEmpty(availableArticles)) {
      value = availableArticles[0]
    }

    return value
  }

  get isNonSingle() {
    return this.productObject.isNonSingle
  }

  get isMiniature() {
    return this.productObject.isMiniature
  }

  get isBoardGame() {
    return this.productObject.isBoardGame
  }

  get isMtgSingleCard() {
    return this.productObject.isMtgSingleCard
  }

  get expansionTitleLoc () {
    return this.productObject.expansionTitleLoc
  }

  get expansionShortCode () {
    return this.productObject.expansionShortCode
  }

  get expansionIcon () {
    return this.productObject.expansionIcon
  }

  get expansionId () {
    return this.productObject.expansionId
  }

  get cheapestPriceLoc() {
    const articles = sortBy(this.parsedArticles, article => article.priceLoc.czk)
    const priceLoc = getLocCurrency(0, 0)

    if(!isEmpty(articles)) {
      const articlePriceLoc = articles[0].priceLoc
      priceLoc.czk = articlePriceLoc.czk
      priceLoc.eur = articlePriceLoc.eur
    }

    return priceLoc
  }

  get priceLoc() {
    let priceLoc = getLocCurrency(0, 0)

    if(this.hasArticles) {
      priceLoc = this.parsedArticles[0].priceLoc
    }

    return priceLoc
  }
}
