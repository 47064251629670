import isObject from 'lodash/isObject'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import CMSTitle from '@/database/models/CMSTitle'
import CMSTag from '@/database/models/CMSTag'

import CMSArticleType from '@/database/models/CMSArticleType'
import { dayMonthYear } from '@/services/DateService'
import AutoCodableN from '~/database/models/AutoCodableN'
import { getLoc } from '~/structures/Localizable'

class CMSArticle extends AutoCodableN {
  static entity = 'cmsarticles'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      created: this.attr(null),
      slug: this.string(null),
      category: this.attr(null),
      article_type: this.string(null),
      tags: this.attr([]),
      titles: this.attr([]),
      localized_name: this.string(null),
      localized_content: this.attr(null),

      name: this.string(null),
      name_cz: this.string(null),
      name_de: this.string(null),
      content: this.attr(null),
      content_cz: this.attr(null),
      content_de: this.attr(null),

      additional_properties: this.attr({}),
      additional_properties_cz: this.attr({}),
      additional_properties_de: this.attr({}),

      author: this.attr(null),
      author_name: this.attr(null),

      image_small: this.attr(null),
      image_medium: this.attr(null),
      image_large: this.attr(null),
      image_small_en: this.attr(null),
      image_medium_en: this.attr(null),
      image_large_en: this.attr(null)
    }
  }

  static propertyMap = new Map([
    ['articleType', 'article_type'],
    ['imageSmall', 'image_small'],
    ['imageMedium', 'image_medium'],
    ['imageLarge', 'image_large'],
    ['imageSmallEn', 'image_small_en'],
    ['imageMediumEn', 'image_medium_en'],
    ['imageLargeEn', 'image_large_en'],
    ['authorName', 'author_name']
  ])

  static additionalProperties = []

  /**
   * @typedef {String} CMSArticle.ArticleType
   * @type {CMSArticle.ArticleType}
   */
  static ArticleType = CMSArticleType

  get createdFormatted () {
    return dayMonthYear(this.created)
  }

  get imageSmallLoc() {
    return getLoc(this.getImageForLang('en', 'Small'), this.getImageForLang('cz', 'Small'), this.getImageForLang('cz', 'Small'))
  }

  get imageMediumLoc() {
    return getLoc(this.getImageForLang('en', 'Medium'), this.getImageForLang('cz', 'Medium'), this.getImageForLang('cz', 'Medium'))
  }

  get imageLargeLoc() {
    return getLoc(this.getImageForLang('en', 'Large'), this.getImageForLang('cz', 'Large'), this.getImageForLang('cz', 'Large'))
  }

  get nameLoc () {
    return this.getLoc('name')
  }

  get contentLoc () {
    return this.getLoc('content') || ''
  }

  get hasAuthor () {
    return !isNull(this.author) && isObject(this.author)
  }

  get hasAuthorName () {
    return !isNull(this.authorName) && !isEmpty(this.authorName)
  }

  get authorObject () {
    return this.author
  }

  get additionalPropertiesLoc () {
    return this.getLoc('additional_properties')
  }

  get authorFullName () {
    return (this.authorObject.first_name || '') + ' ' + (this.authorObject.last_name || '')
  }

  get mainTitle () {
    if (!isEmpty(this.titleObjects)) {
      return this.titleObjects[0]
    }

    return null
  }

  get titleObjects () {
    return map(this.titles, title => new CMSTitle(title))
  }

  get tagObjects () {
    return map(this.tags, tag => new CMSTag(tag))
  }

  getImageForLang(lang = 'en', size = 'Small') {
    let image = this[`image${size}`]
    const imageEn = this[`image${size}En`]

    if(lang === 'en' && imageEn !== null) {
      image = imageEn
    }

    return image
  }
}

export default CMSArticle
