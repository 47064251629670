import CartItem from '@/database/models/CartItem'

export default class SellCartItem extends CartItem {
  static entity = 'sellcartitems'
  static primaryKey = 'id'

  static fields () {
    return {
      ...CartItem.fields(),
      article: this.attr(null),
    }
  }
}
