import Article from '@/database/models/Article'

export default class RecentlyVisitedArticle extends Article {
  static entity = 'recently_visited_articles'
  static primaryKey = 'id'

  static fields () {
    return {
      ...super.fields(),
      orm_timestamp: this.attr(+(new Date()))
    }
  }

  get ormTimestamp () {
    return this.orm_timestamp
  }

  set ormTimestamp (value) {
    this.orm_timestamp = value
  }
}
