import isEmpty from 'lodash/isEmpty'

import AutoCodableN from '~/database/models/AutoCodableN'

export default class StatusMessage extends AutoCodableN {
  static fields() {
    return {
      id: this.attr(1),
      type: this.attr(StatusMessage.Type.success),
      text: this.attr(null),
      text_cz: this.attr(null),
      text_de: this.attr(null)
    }
  }

  static propertyMap = new Map([
    ['textCz', 'text_cz'],
    ['textDe', 'text_de']
  ])

  static Type = {
    success: 'SUCCESS',
    info: 'INFO',
    warning: 'WARNING',
    error: 'ERROR',
  }

  get isEmpty () {
    return isEmpty(this.text) && isEmpty(this.textCz) && isEmpty(this.textDe)
  }

  get contentLoc () {
    return this.getLoc('text')
  }

  get typeLower () {
    return this.type.toLowerCase()
  }
}
