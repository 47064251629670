import forIn from 'lodash/forIn'
import isEmpty from 'lodash/isEmpty'
import { mapData } from '@/services/Helpers'
import StoreModel from '~/database/models/StoreModel'
import { getLoc, getLocCurrency } from '~/structures/Localizable'

class Codable extends StoreModel {
  toJSON () {
    return { ...this } // fix for non-POJO warning
  }

  encode () {
    return this.$toJson()
  }

  encodeToPatch () {
    return this.encode()
  }

  /**
   *
   * @param {Object | Codable} object
   * @param {Object} parameters
   * @returns {{}}
   */
  static mapProperties = function (object, parameters = {}) {
    return mapData(this.propertyMap, object, parameters)
  }

  /**
   *
   * @type {Map<String, String>}
   */
  static propertyMap = new Map()

  /**
   * Generate list of options based on static type
   *
   * @param {Object} typeObject
   * @param {String} stringIdentifier
   * @param {Boolean} byKey
   * @returns {[]}
   */
  static generateTypeOptions (typeObject, stringIdentifier = '', byKey = false) {
    const options = []
    forIn(typeObject, function (value, key) {
      options.push({
        value,
        text: `${stringIdentifier}_${byKey ? key : value}`
      })
    })

    return options
  }

  /**
   * @param {string} key
   * @returns {{cs: string, de: string, en: string}}
   */
  getLoc(key) {
    return getLoc(this[key], this[`${key}_cz`], this[`${key}_de`])
  }

  /**
   * @param {string} key
   * @param {string} defaultValue
   * @returns {{cs: string, de: string, en: string}}
   */
  getLocWithDefault(key, defaultValue = this[key]) {
    return getLoc(isEmpty(this[key]) ? defaultValue : this[key], isEmpty(this[`${key}_cz`]) ? defaultValue : this[`${key}_cz`], isEmpty(this[`${key}_de`]) ? defaultValue : this[`${key}_de`])
  }

  /**
   * @param {string} key
   * @returns {{eur: string|number, czk: string|number}}
   */
  getLocCurrency(key) {
    return getLocCurrency(this[`${key}_czk`], this[`${key}_eur`])
  }
}

export default Codable
